import appState from '#/store/AppStateStore';
import paymentStore from '#/store/PaymentStore';
import { ReservationSummaryAvailabilityEnum, ReservationSummary } from '@jucy/rentals-api-client/rentals-api';
import { BookingPaymentInfoFromJSON } from '@jucy/rentals-api-client/rentals-api-v3';
import { instanceToPlain } from 'class-transformer';
import { addDays, differenceInHours, formatISO9075, parseISO } from 'date-fns';
import get from 'lodash/get';
import { action, autorun, makeAutoObservable, reaction, runInAction, toJS } from 'mobx';
import { toast } from 'react-toastify';
import { Writable } from 'type-fest';
import { CartItem } from '../lib/CartItem';
import ErrorReporter from '../lib/ErrorReporter';
import Events from '../lib/Events';
import { HirerDetailsFormValues } from '../lib/HirerDetailsFormValues';
import JucyAPI from '../lib/JucyAPI.js';
import { jucyAttribution } from '../lib/SalesMonitoring/jucyAttribution';
import { TripInfo } from '../lib/TripInfo';
import AdditionalDriver from '../lib/api/model/AdditionalDriver';
import FleetCategoryAvailability from '../lib/api/model/FleetCategoryAvailability';
import InsuranceProduct from '../lib/api/model/InsuranceProduct';
import Money from '../lib/api/model/Money';
import Product from '../lib/api/model/Product';
import Quote from '../lib/api/model/Quote';
import { setTime } from '../lib/dates';
import { resolveError } from '../lib/resolveError';
import { BookingCart } from '../types/BookingCart';
import { UserMode } from '../types/UserMode';
import accountKeyStore from './AccountKeyStore';
import AvailabilityStore from './AvailabilityStore';
import ProductPricingStore from './ProductStore';
import RentalTripSearchStore from './RentalTripSearchStore';
import RouteRegistry from './RouteRegistry';
import config from '#/config';
import { queryClient } from '#/layouts/ClientOnlyObeLayout/lib/init';
import { bookingQueryKeys } from '@jucy-ui/common/services/queryKeys';
import { getBooking, type GetBookingRequest } from '@jucy-ui/common/services/api';

const activeQuoteKey = 'activeQuote-v2';

const saveActiveQuoteToLocalStorage = (quote?: Quote) => {
    if (quote) {
        localStorage.setItem(activeQuoteKey, JSON.stringify(instanceToPlain(quote.toPlain())));
    } else {
        localStorage.removeItem(activeQuoteKey);
    }
};

export const loadActiveQuoteFromLocalStorage = (): Quote | undefined => {
    const activeQuoteJson = localStorage.getItem(activeQuoteKey);
    const storedQuote = activeQuoteJson ? Quote.fromPlain(activeQuoteJson && JSON.parse(activeQuoteJson)) : undefined;
    if (!storedQuote?.DateCreated || storedQuote?.BookingStatus !== 'Confirmed') {
        return undefined;
    }

    const dateCreated = parseISO(storedQuote.DateCreated);
    const expiresDate = addDays(dateCreated, config.quoteExpireDays);
    const remaining = differenceInHours(expiresDate, new Date());
    if (remaining <= 0 && storedQuote?.BookingStatus !== 'Confirmed') {
        return undefined;
    }
    return storedQuote;
};

class ReservationStore {
    activeQuote?: Quote = undefined;
    originalQuote?: Quote = undefined;
    state: 'loading' | 'pending' | 'error' | 'done' = 'pending';
    message = '';
    error?: Error;
    fleetCategory?: FleetCategoryAvailability;
    confirmation?: { PaymentUrl: string; ReservationReference: string };
    emailResult: 'loading' | 'pending' | 'error' | 'done' = 'pending';
    deposit = false;
    initialized = false;
    isCreatingQuote = false;
    quotes: Quote[] = [];
    reservationSummaries: ReservationSummary[] = [];
    expiredError = false;
    proposedProductsMap: CartItem[] = [];
    userMode: UserMode = 'direct';
    errorType: 'email' | 'dnr' | 'loyalty' | 'error' | '' = '';
    loyaltyId: { type?: string; id?: string } = {};

    constructor() {
        makeAutoObservable(this);

        reaction(
            () => this.state,
            (data) => {
                if (data === 'error') {
                    ErrorReporter.reportError({
                        error: this.error,
                        tags: { store: 'reservation' },
                    });
                } else {
                    this.error = undefined;
                    this.message = '';
                }
            }
        );
    }

    get productMap(): CartItem[] {
        const mappableCategories = ['InsuranceFee', 'SecondaryProduct'];
        return (
            this.proposedProductsMap ||
            this.activeQuote?.Lines?.filter((l) => l.ProductCode && mappableCategories.includes(l.Category)).map((l) => ({
                code: l.ProductCode,
                qty: l.Qty || 1,
            }))
        );
    }

    get bookingNo() {
        return `#${this.activeQuote?.PickUpLocationCode}-${this.activeQuote?.ReservationId}`;
    }

    get total() {
        return {
            currencyCode: this.activeQuote?.TotalPrice.CurrencyCode || '',
            value: this.activeQuote?.TotalPrice.Value || 0,
        };
    }

    get isReadonly() {
        return this.activeQuote?.isConfirmed;
    }

    get canEmailQuote() {
        if (this.activeQuote?.isConfirmed) {
            return false;
        }
        if (this.activeQuote?.deal?.type === 'vehicle-relocation') {
            return false;
        }
        if (this.userMode !== 'direct') {
            return false;
        }
        if (this.activeQuote?.FleetCategory.Availability !== 'FreeSell') {
            return false;
        }
        return this.activeQuote?.FleetCategory.quoteEnabled;
    }

    get onRequestHoldAmount() {
        return {
            currencyCode: this.activeQuote?.TotalPrice.CurrencyCode,
            value: 1,
        };
    }

    get isSelectingCategory() {
        return this.state === 'loading' && !this.activeQuote;
    }

    get isConfirming() {
        return this.state === 'loading' && !this.confirmation;
    }

    get hirerFullName() {
        if (this.activeQuote?.HirerDetails?.FirstName && this.activeQuote.HirerDetails?.LastName) {
            return `${this.activeQuote.HirerDetails.FirstName} ${this.activeQuote.HirerDetails.LastName}`;
        }
        if (this.activeQuote?.HirerDetails?.FirstName) {
            return this.activeQuote.HirerDetails.FirstName;
        }
        return '';
    }

    get EditQuoteLink() {
        return this.activeQuote ? RouteRegistry.getPath('edit-quote', { reservationReference: this.activeQuote.ReservationReference }) : null;
    }

    get editEnabled() {
        return !this.activeQuote || !this.activeQuote.isConfirmed;
    }

    get hasInsuranceProducts() {
        return Boolean(this.activeQuote?.InsuranceProducts && this.activeQuote.InsuranceProducts.length > 0);
    }

    get hasSelectInsurance() {
        return Boolean(this.activeQuote?.InsuranceLine?.ProductCode);
    }

    get hasSecondaryProducts() {
        return Boolean(this.activeQuote?.SecondaryProducts && this.activeQuote.SecondaryProducts.length > 0);
    }

    setValues = (values: Partial<ReservationStore>) => {
        Object.entries(values).forEach(([name, value]) => {
            const self = this as Writable<ReservationStore>;
            const prop = name as keyof Writable<ReservationStore>;
            const currentValue = self[prop];
            runInAction(() => {
                if (currentValue !== value) {
                    self[prop] = value as never;
                }
            });
        });
    };

    init() {
        runInAction(() => {
            this.activeQuote = loadActiveQuoteFromLocalStorage();
            if (this.activeQuote?.PickUpLocationCode) {
                RentalTripSearchStore.setValuesFromQuote(this.activeQuote);
                RentalTripSearchStore.performSearch().catch();
            }
        });
        autorun(() => saveActiveQuoteToLocalStorage(this.activeQuote));
    }
    setAgentVoucherReference(agentVoucherReference: string) {
        if (!agentVoucherReference) {
            agentVoucherReference = this.hirerFullName;
        }
        if (this.activeQuote) {
            this.activeQuote.AgentVoucherReference = agentVoucherReference;
        }
    }

    setDeposit(value: boolean) {
        this.deposit = value;
    }

    setInsurance(insurance: InsuranceProduct) {
        const insuranceCodes = this.activeQuote?.InsuranceProducts[0].Items.map((i) => i.ProductCode);
        this.proposedProductsMap = this.productMap.filter((l) => l.code && !insuranceCodes?.includes(l.code));
        this.proposedProductsMap.push({
            code: insurance.ProductCode,
            qty: 1,
        });

        return this.updateQuotePricing(this.proposedProductsMap);
    }

    updateAddOn(addOn: Product) {
        const existing = this.productMap;
        this.proposedProductsMap = existing.filter((l) => l.code && l.code !== addOn.ProductCode);
        // we push even qty of 0 here because updateQuotePricing's ProductPricingStore returns the previously saved amounts when productMap is empty.
        this.proposedProductsMap.push({
            code: addOn.ProductCode,
            qty: addOn.Qty,
        });
        return this.updateQuotePricing(this.proposedProductsMap);
    }

    updateQuotePricing(productMap: CartItem[]) {
        return (
            this.activeQuote &&
            ProductPricingStore.getQuoteProductPricing(this.activeQuote.ReservationReference, productMap).then(
                action((data) => {
                    if (this.activeQuote && data?.productPrices) {
                        this.activeQuote.TotalPrice = {
                            ...this.activeQuote.TotalPrice,
                            CurrencyCode: this.activeQuote.TotalPrice.CurrencyCode,
                            Value:
                                this.activeQuote.FleetCategory.Total.Value +
                                this.activeQuote
                                    .updateLinePrices(data.productPrices)
                                    .filter((l) => !l.IsExcluded)
                                    .reduce((total, line) => total + line.Total.Value, 0),
                        };
                    }
                    return data;
                })
            )
        );
    }

    async createQuote({ tripInfo, deal, hirerDetails }: { tripInfo: TripInfo; deal?: unknown; hirerDetails?: HirerDetailsFormValues }): Promise<Quote | undefined> {
        this.fleetCategory = tripInfo.fleetCategory;
        const request: Record<string, unknown> = {
            ...(hirerDetails || accountKeyStore.generateDefaultHirerDetailsForLocation()),
            pickUpLocationCode: tripInfo.pickUpLocation?.SiteCode,
            dropOffLocationCode: tripInfo.dropOffLocation?.SiteCode,
            pickUpDateTime: tripInfo.pickUpDate && tripInfo.pickUpTime ? formatISO9075(setTime(tripInfo.pickUpDate, tripInfo.pickUpTime)) : undefined,
            dropOffDateTime: tripInfo.dropOffDate && tripInfo.dropOffTime ? formatISO9075(setTime(tripInfo.dropOffDate, tripInfo.dropOffTime)) : undefined,
            driverAge: tripInfo.driverAge,
            categoryCode: tripInfo.fleetCategory?.CategoryCode,
            campaignCode: tripInfo.campaignCode,
        };
        const attributionParams = jucyAttribution.getForwardUrlSearchParams();
        for (const [param, value] of attributionParams) {
            if (value) {
                request[param] = value;
            }
        }

        runInAction(() => {
            this.isCreatingQuote = true;
            this.state = 'loading';
            this.message = '';
        });

        try {
            const response = await JucyAPI.createQuote(request, accountKeyStore.user);
            runInAction(() => {
                if (response.ResponseType !== JucyAPI.responseTypes.success) {
                    this.message = response.Message;
                    this.state = 'error';
                } else {
                    const { ...quoteData } = response.Data;
                    tripInfo.fleetCategory?.updateFromApiQuoteResponse(response.Data);
                    const quote = Quote.fromApi({
                        deal,
                        ...quoteData,
                        FleetCategory: tripInfo.fleetCategory,
                    });
                    this.updateQuoteCache(quote);
                    this.activeQuote = quote;
                }
                this.isCreatingQuote = false;
            });
        } catch (e) {
            resolveError(e).then(({ error, message }) => {
                runInAction(() => {
                    this.activeQuote = undefined;
                    this.state = 'error';
                    this.error = error;
                    this.message = message;
                    this.isCreatingQuote = false;
                });
            });
        }
        if (this.summary?.fleetCategory) {
            Events.trackFleetCategorySelected({
                ...this.summary.asSearchFormValues(),
                fleetCategory: this.summary.fleetCategory,
            });
        }
        return this.activeQuote;
    }

    async confirmReservation() {
        const secondaryProductsAndInsuranceMap: Record<string, number> = {};
        this.productMap.forEach((l) => {
            secondaryProductsAndInsuranceMap[l.code] = l.qty;
        });
        const quoteId = get(this, 'activeQuote.QuoteId');
        if (!quoteId) {
            return undefined;
        }
        runInAction(() => {
            this.state = 'loading';
            this.message = '';
            this.confirmation = undefined;
        });
        const request = {
            firstName: this.activeQuote?.HirerDetails.FirstName,
            lastName: this.activeQuote?.HirerDetails.LastName,
            email: this.activeQuote?.HirerDetails.Email,
            mobilePhone: this.activeQuote?.HirerDetails.MobileNumber,
            secondaryProductsAndInsuranceMap,
            numberOfPeople: this.activeQuote?.HirerDetails.NumberOfPeople,
            licenseCountry: this.activeQuote?.HirerDetails.DriversLicenceCountry,
            marketingAllowed: this.activeQuote?.HirerDetails.OptIn,
            deposit: this.deposit,
            gateway: this.summary ? paymentStore.getActivePaymentOption(this.summary)?.gateway : undefined,
        };
        const confirmResponse = await JucyAPI.confirmReservation(quoteId, request);

        runInAction(() => {
            if (confirmResponse.ResponseType !== JucyAPI.responseTypes.success) {
                this.message = confirmResponse.Message;
                this.state = 'error';
            } else {
                this.confirmation = confirmResponse.Data;
                if (this.activeQuote) {
                    this.activeQuote.PaymentURL = confirmResponse.Data.PaymentUrl;
                    this.activeQuote.PaymentToken = confirmResponse.Data.PaymentToken;
                }
                this.state = 'done';
            }
        });
    }

    async confirmQuote() {
        const quoteId = get(this, 'activeQuote.QuoteId');
        if (!quoteId) {
            return undefined;
        }
        const request = {
            quoteId: quoteId,
            // NOTE: hirer details are also concatenated in lanier to call GetReservation. If this is updated
            // to take input from the OBE then lanier will need to be updated as well.
            licenseCountry: this.activeQuote?.HirerDetails?.DriversLicenceCountry,
            deposit: this.deposit,
            agentVoucherReference: this.activeQuote?.AgentVoucherReference,
        };
        this.state = 'loading';
        this.message = '';
        this.confirmation = undefined;

        const confirmResponse = await JucyAPI.confirmQuote(request);
        if (confirmResponse.ResponseType !== JucyAPI.responseTypes.success) {
            runInAction(() => {
                this.message = confirmResponse.Message;
                this.state = 'error';
            });
            return this.activeQuote;
        }

        const fetchReservationResponse = await this.fetchReservation(this.activeQuote?.ReservationReference as string, this.activeQuote?.HirerDetails?.LastName || '', false);
        runInAction(() => {
            this.confirmation = confirmResponse.Data;
            this.activeQuote = fetchReservationResponse || undefined;
            if (this.activeQuote && (confirmResponse.Data.PaymentUrl || confirmResponse.Data.PaymentToken)) {
                this.activeQuote.PaymentURL = confirmResponse.Data.PaymentUrl;
                this.activeQuote.PaymentToken = confirmResponse.Data.PaymentToken;
                this.updateQuoteCache(this.activeQuote);
            }
            this.state = 'done';
        });
        return fetchReservationResponse;
    }

    updateQuoteCache(quote?: Quote) {
        if (quote) {
            const existingIndex = this.quotes.findIndex((q) => q.ReservationReference === quote.ReservationReference);
            if (existingIndex >= 0) {
                this.quotes[existingIndex] = quote;
            } else {
                this.quotes.push(quote);
            }
        }
    }

    emailQuoteWeb() {
        const quoteId = get(this, 'activeQuote.QuoteId');
        if (quoteId && this.activeQuote) {
            const request = {
                quoteId: quoteId,
                firstName: this.activeQuote.HirerDetails.FirstName,
                lastName: this.activeQuote.HirerDetails.LastName,
                email: this.activeQuote.HirerDetails.Email,
                mobilePhone: this.activeQuote.HirerDetails.MobileNumber,
                marketingAllowed: this.activeQuote.HirerDetails.OptIn,
            };
            this.state = 'loading';
            this.message = '';
            this.confirmation = undefined;
            return JucyAPI.emailQuoteWeb(request)
                .then(
                    action('fetchSuccess', (response) => {
                        if (response.ResponseType !== JucyAPI.responseTypes.success) {
                            this.message = response.Message;
                            this.state = 'error';
                        } else {
                            this.confirmation = response.Data;
                            this.emailResult = 'done';
                        }
                    })
                )
                .catch(
                    action('fetchError', (e) => {
                        this.state = 'error';
                        this.error = e;
                        this.errorType = 'error';
                        this.message = (e.response && e.response.body && e.response.body.Message) || e.message;
                        toast.error(this.message);
                        throw e;
                    })
                );
        }
        return undefined;
    }

    emailQuote() {
        const quoteId = get(this, 'activeQuote.QuoteId');
        if (quoteId) {
            const request = {
                quoteId: quoteId,
                marketingAllowed: this.activeQuote?.HirerDetails.OptIn,
                deposit: false,
            };
            this.state = 'loading';
            this.message = '';
            this.confirmation = undefined;
            return JucyAPI.emailQuote(request)
                .then(
                    action('fetchSuccess', (response) => {
                        if (response.ResponseType !== JucyAPI.responseTypes.success) {
                            this.message = response.Message;
                            this.state = 'error';
                        } else {
                            this.confirmation = response.Data;
                            this.emailResult = 'done';
                        }
                    })
                )
                .catch(
                    action('fetchError', (e) => {
                        this.state = 'error';
                        this.error = e;
                        this.message = (e.response && e.response.body && e.response.body.Message) || e.message;
                        toast.error(this.message);
                        throw e;
                    })
                );
        }
        return undefined;
    }

    updateCheckInDetails() {
        const request = this.buildReservationUpdate();
        const quoteId = get(this, 'activeQuote.QuoteId');
        if (quoteId) {
            return JucyAPI.updateReservation(quoteId, request)
                .then(
                    action('fetchSuccess', (response) => {
                        if (response.ResponseType !== JucyAPI.responseTypes.success) {
                            this.message = response.Message;
                            this.state = 'error';
                        } else {
                            if (this.activeQuote) {
                                this.activeQuote.BalanceDue = Money.fromApi(response.Data.BalanceDue);
                                this.activeQuote.AdditionalDrivers = response.Data.AdditionalDrivers;
                            }
                            this.state = 'done';
                        }
                    })
                )
                .catch(
                    action('fetchError', (e) => {
                        this.state = 'error';
                        this.error = e;
                        this.message = (e.response && e.response.body && e.response.body.Message) || e.message;
                        toast.error(this.message);
                        throw e;
                    })
                );
        }
        return undefined;
    }

    updateReservation() {
        const secondaryProductsAndInsuranceMap: Record<string, number> = {};
        this.productMap.forEach((l) => {
            secondaryProductsAndInsuranceMap[l.code] = l.qty;
        });

        const request = {
            quoteId: this.activeQuote?.QuoteId,
            secondaryProductsAndInsuranceMap,
            hirerFirstName: this.activeQuote?.HirerDetails.FirstName,
            hirerLastName: this.activeQuote?.HirerDetails.LastName,
            hirerEmail: this.activeQuote?.HirerDetails.Email,
            hirerMobile: this.activeQuote?.HirerDetails.MobileNumber,
            hirerLicenseCountry: this.activeQuote?.HirerDetails.DriversLicenceCountry,
            // add additional details here
            agentVoucherReference: this.activeQuote?.AgentVoucherReference,
            agentName: this.activeQuote?.AgentName,
            marketingAllowed: this.activeQuote?.HirerDetails.OptIn,
            numberOfPeople: this.activeQuote?.HirerDetails.NumberOfPeople,
            loyalty: this.loyaltyId ? JSON.stringify(toJS(this.loyaltyId)) : undefined,
        };

        return JucyAPI.updateQuote(request)
            .then(
                action('fetchSuccess', (response) => {
                    this.errorType = '';
                    if (response.ResponseType !== JucyAPI.responseTypes.success) {
                        this.message = response.Message;
                        this.state = 'error';
                    } else {
                        if (this.activeQuote) {
                            this.activeQuote.FleetCategory.updateFromApiQuoteResponse(response.Data);
                            const quote = Quote.fromApi({
                                ...this.activeQuote,
                                ...response.Data,
                                HirerDetails: this.activeQuote.HirerDetails,
                                deal: this.activeQuote.deal,
                                FleetCategory: this.activeQuote.FleetCategory,
                            });
                            this.updateQuoteCache(quote);
                            this.activeQuote = quote;
                        }
                        this.state = 'done';
                    }
                })
            )
            .catch(
                action('fetchError', (e) => {
                    if (e.response?.body?.Message?.toLowerCase().trim() === 'dnr error') {
                        this.errorType = 'dnr';
                    } else if (e.response && e?.response?.body?.Message?.includes('Email')) {
                        this.errorType = 'email';
                        this.message = e?.response?.body?.Message;
                    } else if (e.response?.body?.Message?.toLowerCase().trim() === 'loyalty error') {
                        this.errorType = 'loyalty';
                    } else {
                        this.errorType = 'error';
                        this.state = 'error';
                        this.error = e;
                        this.message = (e.response && e.response.body && e.response.body.Message) || e.message;
                        toast.error(this.message);
                        throw e;
                    }
                })
            );
    }

    setActiveQuote(quote: Quote) {
        this.activeQuote = quote;
    }

    async fetchReservation(ref: string, lastName?: string | undefined, useCache = true) {
        return this.fetchReservationV2({ ref, lastName, useCache });
    }

    async fetchReservationV2({
        ref,
        lastName,
        useCache = true,
        country,
        resume,
    }: {
        ref: string;
        lastName?: string;
        useCache?: boolean;
        country?: string;
        resume?: boolean;
    }): Promise<Quote | null> {
        this.message = '';
        let result = useCache ? this.quotes.find((q) => q.ReservationReference === ref) : null;
        if (!result) {
            runInAction(() => (this.state = 'loading'));
            try {
                const response = await JucyAPI.getReservation(ref, lastName, country, resume, appState.user);
                if (response?.BookingStatus) {
                    result = Quote.fromApi(response);
                } else {
                    result = !JucyAPI.isErrorResponse(response.ResponseType) ? Quote.fromApi(response.Data) : null;
                }
                if (result) {
                    this.updateQuoteCache(result);
                    // Update summary row of related reservation with values from fetched detail object
                    const summaries = toJS(this.reservationSummaries);
                    const summary = summaries.find((s) => s.ref === ref);
                    if (summary) {
                        summary.status = result.Status as ReservationSummary['status'];
                        summary.availability = result.FleetCategory.Availability === 'FreeSell' ? ReservationSummaryAvailabilityEnum.Freesell : ReservationSummaryAvailabilityEnum.Onrequest;
                        summary.hirer = {
                            ...summary.hirer,
                            firstName: result.HirerDetails.FirstName,
                            lastName: result.HirerDetails.LastName,
                        };
                    }
                    runInAction(() => {
                        this.expiredError = false;
                        this.state = 'done';
                    });
                } else {
                    runInAction(() => {
                        this.expiredError = false;
                        this.state = 'done';
                        this.message = response.Message || 'An unexpected error has occurred';
                    });
                }
            } catch (e) {
                resolveError(e).then(({ error, message }) => {
                    runInAction(() => {
                        this.expiredError =
                            (
                                e as {
                                    response: { body: { expired: boolean } };
                                }
                            ).response?.body?.expired || false;
                        this.state = 'error';
                        this.error = error;
                        this.message = message;
                    });
                });
            }
        }

        return result || null;
    }

    async fetchReservationSummary(ref: string) {
        const summaries = await this.fetchReservationSummaries();
        return summaries.find((s) => s.ref === ref);
    }

    reset = () => {
        this.originalQuote = this.activeQuote;
        this.activeQuote = undefined;
        this.state = 'pending';
        this.fleetCategory = undefined;
        this.confirmation = undefined;
        this.emailResult = 'pending';
        this.message = '';
        this.proposedProductsMap = [];
        localStorage.removeItem(activeQuoteKey);
        this.errorType = '';
    };

    buildReservationUpdate() {
        return this.activeQuote
            ? {
                  reservationReference: this.activeQuote.ReservationReference,
                  numberTravelling: this.activeQuote.HirerDetails.NumberOfPeople ? this.activeQuote.HirerDetails.NumberOfPeople : 1,
                  firstName: this.activeQuote.HirerDetails.FirstName,
                  lastName: this.activeQuote.HirerDetails.LastName,
                  email: this.activeQuote.HirerDetails.Email,
                  mobilePhone: this.activeQuote.HirerDetails.MobileNumber,
                  optIn: this.activeQuote.HirerDetails.OptIn,
                  dateOfBirth: this.activeQuote.HirerDetails.DateOfBirth,
                  licenseNumber: this.activeQuote.HirerDetails.DriversLicenceNumber,
                  licenseCountry: this.activeQuote.HirerDetails.DriversLicenceCountry,
                  licenseExpiry: this.activeQuote.HirerDetails.DriversLicenceExpiry,
                  departureFlightNumber: this.activeQuote.HirerDetails.DepartureFlightNumber,
                  arrivalFlightNumber: this.activeQuote.HirerDetails.ArrivalFlightNumber,
                  lucyClubNumber: this.activeQuote.HirerDetails.LucyClubNumber,
                  address: this.buildAddress(),
                  additionalDrivers: this.buildAdditionalDriverArray(),
              }
            : null;
    }

    buildAddress() {
        return this.activeQuote
            ? {
                  street: this.activeQuote.HirerDetails.HomeAddress.Street,
                  state: this.activeQuote.HirerDetails.HomeAddress.State,
                  city: this.activeQuote.HirerDetails.HomeAddress.City,
                  country: this.activeQuote.HirerDetails.HomeAddress.Country,
                  postCode: this.activeQuote.HirerDetails.HomeAddress.PostCode,
              }
            : null;
    }

    buildAdditionalDriverArray() {
        return this.activeQuote?.AdditionalDrivers.map(this.buildAdditionalDriver);
    }

    buildAdditionalDriver(driver: AdditionalDriver) {
        return {
            firstName: driver.FirstName,
            lastName: driver.LastName,
            isDelete: driver.IsDelete,
            licenseNumber: driver.LicenseNumber,
            licenseCountry: driver.LicenseCountry,
            licenseExpiry: driver.LicenseExpiry,
            ...(driver.AdditionalDriverId ? { additionalDriverId: driver.AdditionalDriverId } : {}),
        };
    }

    async fetchReservationSummaries() {
        this.state = 'loading';
        this.message = '';
        this.reservationSummaries = [];
        try {
            const response = await accountKeyStore.apiClient.reservations.listReservations(accountKeyStore.accountKey);
            runInAction(() => {
                this.state = 'done';
                this.reservationSummaries = response.items || [];
            });
        } catch (e) {
            resolveError(e).then(({ error, message }) => {
                runInAction(() => {
                    this.state = 'error';
                    this.error = error;
                    this.message = message;
                });
            });
        }
        return this.reservationSummaries;
    }

    async cancelReservation() {
        if (this.activeQuote) {
            const request = {
                reservationNumber: this.activeQuote.ReservationReference,
                onlineLogin: this.activeQuote.OnlineLogin,
            };
            return JucyAPI.cancelReservation(request);
        }
    }

    get summary(): BookingCart | undefined {
        if (this.activeQuote) {
            let bookingStatus = this.activeQuote.Status;
            if (this.activeQuote.isConfirmed && this.activeQuote.isRequest) {
                bookingStatus = 'reservation-request';
            }

            const summary = new BookingCart({
                bookingStatus: bookingStatus,
                pickUpLocation: this.activeQuote.PickUpSite,
                dropOffLocation: this.activeQuote.DropOffSite,
                pickUpDate: this.activeQuote.PickUpDateTime,
                dropOffDate: this.activeQuote.DropOffDateDateTime,
                rentalDays: this.activeQuote.RentalDays,
                driverAge: RentalTripSearchStore.driverAge ? Number(RentalTripSearchStore.driverAge) : 0,
                campaignCode: RentalTripSearchStore?.promoCode,
                fleetCategory: this.activeQuote.FleetCategory.asV3FleetCategory(),
                secondaryProducts: this.activeQuote.SecondaryProducts.map((g) => g.asV3ProductGroup()),
                insuranceProducts: this.activeQuote.InsuranceProducts[0]?.asV3InsuranceGroup(),
                selectedExcess: this.activeQuote.InsuranceLine?.asV3ProductLine(),
                mandatoryItems: (this.activeQuote.MandatoryLines?.map((fee) => fee.asV3ProductLine()) || []).sort((p) => p.sortOrder || 0),
                selectedExtras: this.activeQuote.ExtrasLines.map((l) => l.asV3ProductLine()).sort((p) => p.sortOrder || 0),
                hirerDetails: {
                    firstName: this.activeQuote.HirerDetails.FirstName,
                    lastName: this.activeQuote.HirerDetails.LastName,
                    mobileNumber: this.activeQuote.HirerDetails.MobileNumber,
                    email: this.activeQuote.HirerDetails.Email,
                    driversLicenceCountry: this.activeQuote.HirerDetails.DriversLicenceCountry,
                    acceptedTerms: false,
                    mailingList: this.activeQuote.HirerDetails.OptIn,
                    voucherReference: this.activeQuote.AgentVoucherReference,
                    loyaltyId: this.loyaltyId?.type
                        ? {
                              type: this.loyaltyId.type,
                              id: this.loyaltyId.id,
                          }
                        : {},
                },
                convertUrl: this.activeQuote.PaymentURL,
                paymentToken: this.activeQuote.PaymentToken,
                reservationReference: this.activeQuote.ReservationReference,
                reservationId: this.activeQuote.ReservationId ? Number(this.activeQuote.ReservationId) : undefined,
                agentPaymentType: this.activeQuote.AgentPaymentType || undefined,
                payments: this.activeQuote.payments?.map(BookingPaymentInfoFromJSON) || [],
                agentCommission: this.activeQuote.AgentCommission
                    ? {
                          value: this.activeQuote.AgentCommission.value || this.activeQuote.AgentCommission.Value,
                          currencyCode: this.activeQuote.AgentCommission.currencyCode || this.activeQuote.AgentCommission.CurrencyCode,
                      }
                    : undefined,
                userMode: accountKeyStore.mode,
            });
            summary.paymentTypeId = this.activeQuote?.paymentOptionId;
            return summary;
        }

        if (RentalTripSearchStore.hasTripInfo) {
            return new BookingCart({
                pickUpLocation: RentalTripSearchStore.pickUpLocation,
                dropOffLocation: RentalTripSearchStore.dropOffLocation,
                pickUpDate: RentalTripSearchStore.pickUpDateTime,
                dropOffDate: RentalTripSearchStore.dropOffDateTime,
            });
        }
        return undefined;
    }

    async setActiveReservationReference(
        reservationReference: string,
        {
            resume,
            country,
            useCache,
        }: {
            resume?: boolean;
            country?: string;
            useCache?: boolean;
        } = {}
    ) {
        const quote = await this.fetchReservationV2({
            ref: reservationReference,
            useCache: typeof useCache !== 'undefined' ? useCache : true,
            resume,
            country,
        });
        if (quote && (!this.activeQuote || this.activeQuote?.ReservationReference !== quote?.ReservationReference)) {
            if (!quote?.InsuranceLine && this.activeQuote?.InsuranceLine) {
                quote.Lines = quote.Lines || [];
                quote.Lines.push(this.activeQuote?.InsuranceLine);
            }
            this.setActiveQuote(quote);
            this.quotes = [quote, ...this.quotes.filter((q) => q.ReservationReference !== quote.ReservationReference)];
        }
        if (this.activeQuote) {
            RentalTripSearchStore.setValuesFromQuote(this.activeQuote);
            AvailabilityStore.setValuesFromQuote(this.activeQuote);
            if (!this.activeQuote?.isConfirmed) {
                RentalTripSearchStore.performSearch().catch(ErrorReporter.captureError);
            }
        }
    }

    fetchBooking(request: GetBookingRequest) {
        return queryClient.fetchQuery({
            queryKey: bookingQueryKeys.get(request),
            queryFn: () => getBooking(request),
        });
    }
}

const reservationStoreInstance = new ReservationStore();

export default reservationStoreInstance;
