import { mapFleetTypeSlugToBrand } from '../../../lib';
import { mapProductLineToGtmItem } from './mapProductLineToGtmItem';
import { mapFleetCategoryToGtmItem } from './mapFleetCategoryToGtmItem';
import { Booking } from '@jucy/rentals-api-client/rentals-api-v3/models/Booking';
import { GtmEventParams } from '../types/GtmEventParams';

export const mapBookingToGtmEventParams = (booking: Booking): GtmEventParams => ({
    currency: booking.totalPrice.currencyCode,
    value: booking.totalPrice.value,
    coupon: booking.fleetCategory.campaignCode,
    transaction_id: booking.reservationReference,
    items: [
        {
            ...mapFleetCategoryToGtmItem(booking.fleetCategory),
            brand: mapFleetTypeSlugToBrand(booking.fleetCategory.fleetTypeCode),
            location_id: booking.pickUpLocation,
        },
        ...(booking.lines || []).map((line) => ({
            ...mapProductLineToGtmItem(line),
            brand: mapFleetTypeSlugToBrand(booking.fleetCategory.fleetTypeCode),
            location_id: booking.pickUpLocation,
        })),
    ],
});
