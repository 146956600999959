import convict from 'convict';
import { Config } from './Config';
import createConfig from './EnvConfig';
import configSchema from './configSchema';
import * as process from 'process';

const config = createConfig<Config>({
    namespace: 'jucy.config',
    defaultConfig: convict(configSchema)
        .load({
            env: process.env.NEXT_PUBLIC_ENV,
            baseUrl: {
                jucy: process.env.NEXT_PUBLIC_JUCY_OBE_URL,
                star: process.env.NEXT_PUBLIC_STAR_OBE_URL,
            },
            dashboardUrl: {
                jucy: process.env.NEXT_PUBLIC_DASHBOARD_BASE_URL,
                star: process.env.NEXT_PUBLIC_STAR_DASHBOARD_BASE_URL,
            },
            apiBaseUrl: process.env.NEXT_PUBLIC_API_BASE_URL,
            authDomain: process.env.NEXT_PUBLIC_AUTH_DOMAIN,
            claimsNamespace: process.env.NEXT_PUBLIC_CLAIMS_NAMESPACE,
            clientId: process.env.NEXT_PUBLIC_CLIENT_ID,
            commissionFactoryId: process.env.NEXT_PUBLIC_COMMISSION_FACTORY_ID,
            commissionJunctionEnterpriseId: process.env.NEXT_PUBLIC_COMMISSION_JUNCTION_ENTERPRISE_ID,
            commissionJunctionTagId: process.env.NEXT_PUBLIC_COMMISSION_JUNCTION_TAG_ID,
            startCommissionFactoryId: process.env.NEXT_PUBLIC_STAR_COMMISSION_FACTORY_ID,
            contentApiBaseUrl: process.env.NEXT_PUBLIC_CONTENT_API_BASE_URL,
            editBookingEmailAddress: process.env.NEXT_PUBLIC_EDITBOOKING_EMAIL_ADDRESS,
            editBookingSubjectTemplate: process.env.NEXT_PUBLIC_EDITBOOKING_EMAIL_SUBJECT,
            enableAfterpay: process.env.NEXT_PUBLIC_AFTERPAY_ENABLED,
            enableAlternateDates: process.env.NEXT_PUBLIC_ENABLE_ALTERNATE_DATES,
            enableDepositPayment: process.env.NEXT_PUBLIC_ENABLE_DEPOSIT_PAYMENT,
            enableExtraItemsDescription: process.env.NEXT_PUBLIC_SHOW_EXTRA_ITEMS_DESCRIPTION,
            enableFullPayment: process.env.NEXT_PUBLIC_ENABLE_FULL_PAYMENT,
            enableLaybuy: process.env.NEXT_PUBLIC_ENABLE_LAYBUY,
            enableTrip: process.env.NEXT_PUBLIC_ENABLE_TRIP,
            envolveIdAU: process.env.NEXT_PUBLIC_AU_ENVOLVE_ID,
            envolveIdNZ: process.env.NEXT_PUBLIC_NZ_ENVOLVE_ID,
            gtm: {
                jucy: {
                    auth: process.env.NEXT_PUBLIC_GTM_AUTH_DIRECT,
                    id: process.env.NEXT_PUBLIC_GTM_ID_DIRECT,
                    preview: process.env.NEXT_PUBLIC_GTM_PREVIEW_DIRECT,
                },
                star: {
                    auth: process.env.NEXT_PUBLIC_STAR_GTM_AUTH_DIRECT,
                    id: process.env.NEXT_PUBLIC_STAR_GTM_ID_DIRECT,
                    preview: process.env.NEXT_PUBLIC_STAR_GTM_PREVIEW_DIRECT,
                },
            },
            tradedoublerId: process.env.NEXT_PUBLIC_TRADEDOUBLER_ID,
            version: process.env.NEXT_PUBLIC_BITBUCKET_BUILD_NUMBER,
            intercomApiBase: process.env.NEXT_PUBLIC_INTERCOM_API_BASE_URL,
            intercomAppId: process.env.NEXT_PUBLIC_INTERCOM_APP_ID,
            webAccountKey: process.env.NEXT_PUBLIC_WEB_ACCOUNT_KEY,
            websiteBaseUrl: process.env.NEXT_PUBLIC_WEBSITE_BASE_URL,
            starWebsiteBaseUrl: process.env.NEXT_PUBLIC_STAR_WEBSITE_BASE_URL,
            enableMotorHomes: process.env.NEXT_PUBLIC_ENABLE_MOTORHOMES,
            modernSearchFrom: process.env.NEXT_PUBLIC_MODERN_SEARCH_FORM,
            logTracking: process.env.NEXT_PUBLIC_LOG_TRACKING,
            azure: {
                redirectUri: process.env.NEXT_PUBLIC_AZURE_REDIRECT_URI,
                clientId: process.env.NEXT_PUBLIC_AZURE_CLIENT_ID,
                authority: process.env.NEXT_PUBLIC_AZURE_AUTHORITY,
            },
            gOptimizeContainerId: process.env.NEXT_PUBLIC_GOPT_CONTAINER_ID,
            hotJarId: process.env.NEXT_PUBLIC_HOTJAR_ID,
            clarityKey: process.env.NEXT_PUBLIC_CLARITY_KEY,
            jitsuKey: process.env.NEXT_PUBLIC_JITSU_KEY,
            growthBookKey: process.env.NEXT_PUBLIC_GROWTH_BOOK_KEY,
            tradedoublerOrganizationId: process.env.NEXT_PUBLIC_TRADEDOUBLER_ORG_ID,
            stripe: {
                au: {
                    apiKey: process.env.NEXT_PUBLIC_STRIPE_AU_API_KEY,
                },
                nz: {
                    apiKey: process.env.NEXT_PUBLIC_STRIPE_NZ_API_KEY,
                },
            },
            insightsEnabled: process.env.NEXT_PUBLIC_INSIGHTS_ENABLED,
            debugAnalytics: process.env.NEXT_PUBLIC_DEBUG_ANALYTICS,
        } as unknown)
        .getProperties(),
});

export default config;
