import { KeenSliderInstance, KeenSliderOptions, TrackDetails } from 'keen-slider/react';
import { observer } from 'mobx-react-lite';
import React, { useMemo, useState } from 'react';
import ErrorReporter from '#/lib/ErrorReporter';
import { useMediaQuery, useTheme } from '@mui/material';
import { addDays, isSameDay } from 'date-fns';
import 'keen-slider/keen-slider.min.css';
import { makeAutoObservable, reaction } from 'mobx';
import { AvailabilitySearchResult, alternateDatesStore } from '../../../../store/AlternateDatesStore';
import { useTeaserContext } from '../Teaser/TeaserContext';
import JucySlider from '../Teaser/components/JucySlider';
import AlternateAvailabilitySlide from './AlternateDatesSlide';
import analytics from '#/services/analytics';

class AlternateDatesSliderStore {
    currentSlide = 0;
    currentIndex = 0;
    previousIndex = 0;
    loaded = false;
    slider?: KeenSliderInstance;

    constructor() {
        makeAutoObservable(this, {
            slider: false,
        });
        this.currentIndex = this.initialIndex;
        reaction(
            () => this.currentSlide,
            (current, prev) => {
                this.previousIndex = prev || this.initialIndex;
                this.currentIndex = current;
                const direction = this.currentIndex > this.previousIndex ? 'next' : 'prev';
                const upcomingIndex = direction === 'next' ? this.currentIndex + 3 : this.currentIndex - 3;
                const shouldLoad = Boolean(this.slides[upcomingIndex]?.placeholder);
                if (!this.slider || !shouldLoad) {
                    return;
                }
                const distance = Math.abs(upcomingIndex - this.initialIndex);
                const remainder = distance % 11;
                if (direction === 'prev') {
                    const dateToLoad = this.slides[upcomingIndex - remainder]?.pickUpDate || null;
                    alternateDatesStore.loadPage(dateToLoad).catch(ErrorReporter.captureError);
                }
                if (direction === 'next') {
                    const dateToLoad = this.slides[upcomingIndex + (11 - remainder)]?.pickUpDate;
                    alternateDatesStore.loadPage(dateToLoad).catch(ErrorReporter.captureError);
                }
            }
        );
    }

    get dates() {
        const start = new Date();
        const end = addDays(alternateDatesStore.trip.pickUpDate as Date, 365);
        const dates = [];
        let currentDate = start;
        while (currentDate <= end) {
            dates.push(new Date(currentDate));
            currentDate = addDays(currentDate, 1);
        }
        return dates;
    }

    get slides(): AvailabilitySearchResult[] {
        return this.dates.map((date) => {
            const availability = alternateDatesStore.results.find((r) => isSameDay(r.pickUpDate, date));
            return availability
                ? availability
                : {
                      ...alternateDatesStore.results[0],
                      fleetCategories: [],
                      placeholder: true,
                      pickUpDate: date,
                      dropOffDate: addDays(date, alternateDatesStore.hireDays),
                  };
        });
    }

    get initialIndex() {
        return this.slides.findIndex((s) => isSameDay(s.pickUpDate, alternateDatesStore.trip.pickUpDate as Date));
    }

    get ready() {
        return Boolean(this.slider && this.loaded);
    }
}

const useSlidesPerView = () => {
    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.only('xs'));
    const isSm = useMediaQuery(theme.breakpoints.only('sm'));
    const isMd = useMediaQuery(theme.breakpoints.only('md'));
    return useMemo(() => {
        if (isXs) {
            return 3;
        }
        if (isMd || isSm) {
            return 5;
        }
        return 7;
    }, [isMd, isSm, isXs]);
};

const AlternateDatesSlider: React.FC = observer(() => {
    const { fleetCategory } = useTeaserContext();
    const [store] = useState(() => new AlternateDatesSliderStore());
    const slideCount = store.slides.length;
    const max = store.slides.length - 3;
    const min = 3;
    const initial = store.initialIndex;
    const slidesPerView = useSlidesPerView();
    const numberSlides = slidesPerView + 1;
    const [slideDetails, setSlideDetails] = useState<TrackDetails['slides']>([]);
    const [isAnimating, setIsAnimating] = useState(false);
    const options: KeenSliderOptions = useMemo(
        () => ({
            initial: initial,
            loop: true,
            range: {
                align: true,
                min: min,
                max: max,
            },
            slides: {
                origin: 'center',
                perView: slidesPerView,
                slides: slideCount,
                spacing: 10,
            },
            mode: 'free-snap',
            detailsChanged: (s) => {
                if (s.track.details?.slides) {
                    setSlideDetails(s.track.details.slides);
                }
            },
            animationStarted: () => {
                setIsAnimating(true);
            },
            animationEnded: () => {
                setIsAnimating(false);
            },
            slideChanged: (slider) => {
                const direction = store.currentSlide > store.previousIndex ? 'next' : 'prev';
                store.currentSlide = slider.track.details.abs;
                analytics.plugins.interactions.trackInteraction({
                    source: 'OBE',
                    category: 'alternate-trip',
                    action: 'slide',
                    label: `Show ${direction}`,
                });
            },
            created: (instanceRef) => {
                store.slider = instanceRef;
            },
        }),
        [initial, max, slidesPerView, slideCount, store]
    );
    return (
        <JucySlider
            showNav={true}
            sx={{
                py: 1,
                mb: -2.5,
                zIndex: 1,
                '&.keen-slider:not([data-keen-slider-disabled])': {
                    overflowX: 'hidden',
                    overflowY: 'visible',
                },
                '&.keen-slider:not([data-keen-slider-disabled]) .keen-slider__slide': {
                    overflow: 'visible',
                },
            }}
            navSx={{
                zIndex: 1,
                mt: -1,
            }}
            options={options}
        >
            {[...Array(numberSlides).keys()].map((idx) => {
                const slideIndex = slideDetails?.[idx]?.abs;
                const availability = slideIndex !== undefined ? store.slides[slideIndex] : undefined;
                const altFleetCategory = availability?.fleetCategories?.find((fc) => fc.code === fleetCategory.CategoryCode);
                return <AlternateAvailabilitySlide key={idx} fleetCategory={altFleetCategory} availability={availability} isAnimating={isAnimating} />;
            })}
        </JucySlider>
    );
});
AlternateDatesSlider.displayName = 'AlternateDatesSlider';

const AlternateDateSliderLoader = observer(() => {
    const { fleetCategory } = useTeaserContext();
    const { deal } = useTeaserContext();

    if (deal?.type === 'vehicle-relocation') {
        return null;
    }
    const hasAlternateDates = alternateDatesStore.results.some((result) => result.fleetCategories.some((fc) => fc.code === fleetCategory.CategoryCode));
    if (!hasAlternateDates || !alternateDatesStore.results?.length) {
        return null;
    }
    return <AlternateDatesSlider />;
});
export default AlternateDateSliderLoader;
