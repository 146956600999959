import * as Yup from 'yup';

export type DirectHirerDetailsValuesSchemaCreateOptions = {
    maxOccupants?: number;
};

export const createHirerDetailsValuesSchema = ({ maxOccupants }: DirectHirerDetailsValuesSchemaCreateOptions) => {
    let numberOfPeople = Yup.number().min(1).integer().required('Please enter the number of people travelling');
    if (maxOccupants) {
        numberOfPeople = numberOfPeople.max(maxOccupants, 'Number of passengers exceeds capacity of vehicle');
    }

    return Yup.object().shape({
        firstName: Yup.string().trim().required('Please enter your first name'),
        lastName: Yup.string().trim().required('Please enter your last name'),
        mobileNumber: Yup.string().trim().required('Please enter your mobile number').min(8, 'Please enter your mobile number'),
        email: Yup.string().email().trim().required('Please enter your email address'),
        driversLicenceCountry: Yup.string().trim().required('Please select your driver license country of issue'),
        paymentType: Yup.string().trim().required('Please select your payment type'),
        numberOfPeople,
        termsAndConditions: Yup.bool()
            .test('termsAndConditions', 'Please accept our terms and conditions', (value) => value)
            .required('Please accept our terms and conditions'),
        mailingList: Yup.boolean().optional(),
        velocityMembershipId: Yup.string().optional(),
    });
};

export type DirectHirerDetailsFormValues = Yup.InferType<ReturnType<typeof createHirerDetailsValuesSchema>>;
