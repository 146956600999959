import Cookies from 'universal-cookie';

export const cookies = new Cookies();

export const getGAClientId = () => {
    if (typeof window === 'undefined') {
        return null;
    }
    const cookieValue = cookies.get('_ga');
    if (cookieValue) {
        const clientID = cookieValue.split('.').slice(2).join('.');
        return typeof clientID === 'string' ? clientID : null;
    }
    return null;
};

export const getGASessionId = () => {
    if (typeof window === 'undefined') {
        return null;
    }
    const cookieValue = cookies.get('_ga');
    if (cookieValue) {
        const sessionId = cookieValue.split('.')[1];
        return typeof sessionId === 'string' ? sessionId : null;
    }
    return null;
};
