import { AnalyticsPayload, AnalyticsPlugin, AnalyticsPluginArgs, AnalyticsPluginIdentifyArgs } from 'analytics';
import { datadogRum } from '@datadog/browser-rum';
import { GtmEventParams } from './analyticsJucyEcommerce';
import { hashIfEmail, mapFleetTypeSlugToBrand, trimIfString } from '../lib';

const pluginName = 'datadog';

const isAnalyticsPayload = (payload: unknown): payload is AnalyticsPayload => {
    if (!payload || typeof payload !== 'object') {
        return false;
    }
    if (!('event' in payload && typeof payload.event === 'string')) {
        return false;
    }
    if (!('properties' in payload && payload.properties && typeof payload.properties === 'object')) {
        return false;
    }
    return true;
};

const isEcommerceEvent = (payload: unknown): payload is AnalyticsPayload<{ ecommerce: GtmEventParams }> => {
    if (!isAnalyticsPayload(payload)) {
        return false;
    }
    if (!('ecommerce' in payload.properties && payload.properties.ecommerce && typeof payload.properties.ecommerce === 'object')) {
        return false;
    }
    return true;
};

export const analyticsJucyDatadog = (pluginConfig: { debug?: boolean } = {}): AnalyticsPlugin => ({
    name: pluginName,
    config: pluginConfig,
    page: ({ payload, config }: AnalyticsPluginArgs) => {
        if (config.debug) {
            // eslint-disable-next-line no-console
            console.log(`[${pluginName}] page`, payload);
        }
    },
    track: ({ payload, config }: AnalyticsPluginArgs) => {
        if (payload.event === 'bookingInitiated') {
            const formattedPayload = {
                brand: payload.properties.brand,
                region: payload.properties.region,
                category: payload.properties.fleetType,
                pickUpLocation: payload.properties.pickUpLocation,
                pickUpDate: payload.properties.pickUpDate,
                pickUpTime: payload.properties.pickUpTime,
                dropOffLocation: payload.properties.dropOffLocation,
                dropOffDate: payload.properties.dropOffDate,
                dropOffTime: payload.properties.dropOffTime,
                campaignCode: payload.properties.coupon,
                businessUnit: `${payload.properties.brand} ${payload.properties.region}`,
                accountType: payload.properties.accountType,
            };
            if (config.debug) {
                // eslint-disable-next-line no-console
                console.log(`[${pluginName}] search`, formattedPayload);
            }
            datadogRum.addAction('search', formattedPayload);
            return;
        }

        if (payload.event === 'view_item_list' && isEcommerceEvent(payload)) {
            const formattedPayload = {
                fleetCategories: (payload.properties.ecommerce.items || []).map((item, i) => ({
                    name: item.item_name,
                    id: item.item_id,
                    price: item.price && item.quantity ? Number(item.price) * item.quantity : 0,
                    brand: mapFleetTypeSlugToBrand(item.item_category),
                    category: item.item_category,
                    list: payload.properties.ecommerce.item_list_name,
                    position: i,
                    dimension13: item.item_category2,
                    metric1: item.price,
                })),
            };
            if (config.debug) {
                // eslint-disable-next-line no-console
                console.log(`[${pluginName}] product-impressions`, formattedPayload);
            }
            datadogRum.addAction('product-impressions', formattedPayload);
            return;
        }

        if (payload.event === 'view_item' && isEcommerceEvent(payload) && payload.properties.ecommerce.items?.length) {
            const item = payload.properties.ecommerce.items[0];
            const formattedPayload = {
                fleetCategory: {
                    name: item.item_name,
                    id: item.item_id,
                    price: item.price && item.quantity ? Number(item.price) * item.quantity : 0,
                    brand: mapFleetTypeSlugToBrand(item.item_category),
                    category: item.item_category,
                    dimension13: item.item_category2,
                    metric1: item.price,
                },
            };
            if (config.debug) {
                // eslint-disable-next-line no-console
                console.log(`[${pluginName}] product-impression`, formattedPayload);
            }
            datadogRum.addAction('product-impression', formattedPayload);
            return;
        }
        if (config.debug) {
            // eslint-disable-next-line no-console
            console.log(`[${pluginName}] track ${payload.event}`, payload);
        }
        datadogRum.addAction(payload.event, payload.properties);
    },
    identify: (args: AnalyticsPluginIdentifyArgs) => {
        const { email, firstName, lastName, ...traits } = args.payload.traits;
        const user = {
            id: trimIfString(hashIfEmail(args.payload.userId)),
            anonymousId: args.payload.anonymousId,
            email: trimIfString(email)?.toLowerCase(),
            name: [trimIfString(firstName), trimIfString(lastName)].filter(Boolean).join(' '),
            ...traits,
        };
        if (args.config?.debug) {
            // eslint-disable-next-line no-console
            console.log(`[${pluginName}] identify`, user);
        }
        datadogRum.setUser(user);
    },
    loaded: () => true,
});
