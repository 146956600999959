import React from 'react';
import { useTranslation } from 'react-i18next';
import DoneIcon from '@mui/icons-material/Done';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Box, List, ListItem, ListItemIcon, ListItemText, Button as MuiButton, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import TranslationStore from '../../../../../store/TranslationStore';
import { useTeaserContext } from '../TeaserContext';
import Events from '#/lib/Events';
import AvailabilityStore from '#/store/AvailabilityStore';

const FleetCategoryDetails: React.FC = () => {
    const { fleetCategory, toggleDetailsModal, detailIcons } = useTeaserContext();
    const { t } = useTranslation(TranslationStore.currentNamespace);
    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.only('xs'));
    return (
        <>
            <List disablePadding={true} dense={true} sx={{ fontSize: '14px', fontWeight: 400, mb: 2, mx: isXs ? 'auto' : undefined }}>
                {fleetCategory.product.highlightedFeatures.slice(0, 4).map((feature) => (
                    <ListItem disablePadding={true} key={`features-${feature}`}>
                        <ListItemIcon sx={{ minWidth: 31 }}>
                            <DoneIcon fontWeight="bold" color="secondary" />
                        </ListItemIcon>
                        <ListItemText disableTypography={true} sx={{ marginTop: 0, marginBottom: 0 }} primary={feature} />
                    </ListItem>
                ))}
            </List>
            {toggleDetailsModal || fleetCategory.product.url ? (
                <div>
                    <MuiButton
                        sx={{
                            textTransform: 'unset',
                            fontSize: '12px',
                            mb: 2,
                            width: isXs ? '100%' : undefined,
                        }}
                        variant="text"
                        data-cy="more-details-link"
                        href={fleetCategory.product.url}
                        title={`${t('fleet_result_list.group.get_more_details')}: ${fleetCategory.product.name}`}
                        onClick={(e) => {
                            Events.trackProductImpression({
                                ...AvailabilityStore.asTripInfo(),
                                fleetCategory: fleetCategory.asV3FleetCategory(),
                                fleetType: AvailabilityStore.fleetType,
                                listId: 'availability',
                                listName: 'OBE Search results',
                            });
                            toggleDetailsModal(e);
                        }}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {t('fleet_result_list.group.get_more_details')}
                        <OpenInNewIcon sx={{ fontSize: '15px', ml: 1 }} />
                    </MuiButton>
                </div>
            ) : null}
            <Stack spacing={1.5} direction="row" sx={{ mx: isXs ? 'auto' : undefined }}>
                {detailIcons
                    .filter((d) => d.count > 0)
                    .map((d, i) => (
                        <Box key={`details-${i}`} title={d.title} display="flex" alignItems="center">
                            {d.icon}
                            <Typography component="span" variant="h6" sx={{ fontWeight: 700, ml: 0.5 }}>
                                {d.count}
                            </Typography>
                        </Box>
                    ))}
            </Stack>
        </>
    );
};
export default FleetCategoryDetails;
