export const defaultFunnelProgress = {
    bookingInitiated: false,
    availabilityList: false,
    excessList: false,
    extrasList: false,
    paymentList: false,
    hirerDetails: false,
    paymentDetails: false,
    bookingComplete: false,
};
