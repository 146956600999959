import convict from 'convict';
import { Config } from './Config';

export type ConfigSchema = convict.Schema<Config>;

const frontEndConfigSchema: ConfigSchema = {
    env: {
        format: String,
        default: null,
        env: 'NEXT_PUBLIC_ENV',
    },
    apiBaseUrl: {
        format: String,
        default: null,
        env: 'NEXT_PUBLIC_API_BASE_URL',
    },
    baseUrl: {
        jucy: {
            format: String,
            default: null,
            env: 'NEXT_PUBLIC_JUCY_OBE_URL',
        },
        star: {
            format: String,
            default: null,
            env: 'NEXT_PUBLIC_STAR_OBE_URL',
        },
    },
    dashboardUrl: {
        jucy: {
            format: String,
            default: null,
            env: 'NEXT_PUBLIC_DASHBOARD_BASE_URL',
        },
        star: {
            format: String,
            default: null,
            env: 'NEXT_PUBLIC_STAR_DASHBOARD_BASE_URL',
        },
    },
    authDomain: {
        format: String,
        default: null,
        env: 'NEXT_PUBLIC_AUTH_DOMAIN',
    },
    claimsNamespace: {
        format: String,
        default: null,
        env: 'NEXT_PUBLIC_CLAIMS_NAMESPACE',
    },
    clientId: {
        format: String,
        default: null,
        env: 'NEXT_PUBLIC_CLIENT_ID',
    },
    commissionFactoryId: {
        format: String,
        default: null,
        env: 'NEXT_PUBLIC_COMMISSION_FACTORY_ID',
    },
    commissionJunctionEnterpriseId: {
        format: String,
        default: null,
        env: 'NEXT_PUBLIC_COMMISSION_JUNCTION_ENTERPRISE_ID',
    },
    commissionJunctionTagId: {
        format: String,
        default: null,
        env: 'NEXT_PUBLIC_COMMISSION_JUNCTION_TAG_ID',
    },
    starCommissionFactoryId: {
        format: String,
        default: null,
        env: 'NEXT_PUBLIC_STAR_COMMISSION_FACTORY_ID',
    },
    dataApiBaseUrl: {
        format: String,
        default: null,
        env: 'NEXT_PUBLIC_DATA_API_BASE_URL',
    },
    editBookingEmailAddress: {
        format: String,
        default: 'agents@jucyworld.com',
        env: 'NEXT_PUBLIC_EDITBOOKING_EMAIL_ADDRESS',
    },
    editBookingSubjectTemplate: {
        format: String,
        default: 'Request changes for booking ref #$ReservationReference',
        env: 'NEXT_PUBLIC_EDITBOOKING_EMAIL_SUBJECT',
    },
    enableAfterpay: {
        format: Boolean,
        default: true,
        env: 'NEXT_PUBLIC_AFTERPAY_ENABLED',
    },
    enableAlternateDates: {
        format: Boolean,
        default: true,
        env: 'NEXT_PUBLIC_ENABLE_ALTERNATE_DATES',
    },
    enableDepositPayment: {
        format: Boolean,
        default: true,
        env: 'NEXT_PUBLIC_ENABLE_DEPOSIT_PAYMENT',
    },
    enableExtraItemsDescription: {
        format: Boolean,
        default: null,
        env: 'NEXT_PUBLIC_SHOW_EXTRA_ITEMS_DESCRIPTION',
    },
    enableFullPayment: {
        format: Boolean,
        default: true,
        env: 'NEXT_PUBLIC_ENABLE_FULL_PAYMENT',
    },
    enableLaybuy: {
        format: Boolean,
        default: true,
        env: 'NEXT_PUBLIC_ENABLE_LAYBUY',
    },
    enableTrip: {
        format: Boolean,
        default: true,
        env: 'NEXT_PUBLIC_ENABLE_TRIP',
    },
    envolveIdAU: {
        format: String,
        default: null,
        env: 'NEXT_PUBLIC_AU_ENVOLVE_ID',
    },
    envolveIdNZ: {
        format: String,
        default: null,
        env: 'NEXT_PUBLIC_NZ_ENVOLVE_ID',
    },
    gtm: {
        jucy: {
            auth: {
                format: String,
                default: null,
                env: 'NEXT_PUBLIC_GTM_AUTH_DIRECT',
            },
            id: {
                format: String,
                default: null,
                env: 'NEXT_PUBLIC_GTM_ID_DIRECT',
            },
            preview: {
                format: String,
                default: null,
                env: 'NEXT_PUBLIC_GTM_PREVIEW_DIRECT',
            },
        },
        star: {
            auth: {
                format: String,
                default: '',
                env: 'NEXT_PUBLIC_STAR_GTM_AUTH_DIRECT',
            },
            id: {
                format: String,
                default: '',
                env: 'NEXT_PUBLIC_STAR_GTM_ID_DIRECT',
            },
            preview: {
                format: String,
                default: '',
                env: 'NEXT_PUBLIC_STAR_GTM_PREVIEW_DIRECT',
            },
        },
    },
    version: {
        format: String,
        default: 'dev',
        env: 'NEXT_PUBLIC_BITBUCKET_BUILD_NUMBER',
    },
    intercomApiBase: {
        format: String,
        default: null,
        env: 'NEXT_PUBLIC_INTERCOM_API_BASE_URL',
    },
    intercomAppId: {
        format: String,
        default: null,
        env: 'NEXT_PUBLIC_INTERCOM_APP_ID',
    },
    webAccountKey: {
        format: String,
        default: null,
        env: 'NEXT_PUBLIC_WEB_ACCOUNT_KEY',
    },
    websiteBaseUrl: {
        format: String,
        default: null,
        env: 'NEXT_PUBLIC_WEBSITE_BASE_URL',
    },
    starWebsiteBaseUrl: {
        format: String,
        default: null,
        env: 'NEXT_PUBLIC_STAR_WEBSITE_BASE_URL',
    },
    enableMotorHomes: {
        format: Boolean,
        default: false,
        env: 'NEXT_PUBLIC_ENABLE_MOTORHOMES',
    },
    modernSearchFrom: {
        format: Boolean,
        default: false,
        env: 'NEXT_PUBLIC_MODERN_SEARCH_FORM',
    },
    logTracking: {
        format: Boolean,
        default: false,
        env: 'NEXT_PUBLIC_LOG_TRACKING',
    },
    azure: {
        redirectUri: {
            format: String,
            default: null,
            env: 'NEXT_PUBLIC_AZURE_REDIRECT_URI',
        },
        clientId: {
            format: String,
            default: null,
            env: 'NEXT_PUBLIC_AZURE_CLIENT_ID',
        },
        authority: {
            format: String,
            default: null,
            env: 'NEXT_PUBLIC_AZURE_AUTHORITY',
        },
    },
    gOptimizeContainerId: {
        format: String,
        default: null,
        env: 'NEXT_PUBLIC_GOPT_CONTAINER_ID',
    },
    hotJarId: {
        format: String,
        default: null,
        env: 'NEXT_PUBLIC_HOTJAR_ID',
    },
    clarityKey: {
        format: String,
        default: '',
        env: 'NEXT_PUBLIC_CLARITY_KEY',
    },
    jitsuKey: {
        format: String,
        default: '',
        env: 'NEXT_PUBLIC_JITSU_KEY',
    },
    growthBookKey: {
        format: String,
        default: '',
        env: 'NEXT_PUBLIC_GROWTH_BOOK_KEY',
    },
    auth0RedirectPath: {
        format: String,
        default: '/auth0/callback',
    },
    tradedoublerOrganizationId: {
        format: Number,
        default: 0,
        env: 'NEXT_PUBLIC_TRADEDOUBLER_ORG_ID',
    },
    stripe: {
        au: {
            apiKey: {
                format: String,
                default: '',
                env: 'NEXT_PUBLIC_STRIPE_AU_API_KEY',
            },
        },
        nz: {
            apiKey: {
                format: String,
                default: '',
                env: 'NEXT_PUBLIC_STRIPE_NZ_API_KEY',
            },
        },
    },
    insightsEnabled: {
        format: Boolean,
        default: false,
        env: 'NEXT_PUBLIC_INSIGHTS_ENABLED',
    },
    quoteExpireDays: {
        format: Number,
        default: 3,
        env: 'NEXT_PUBLIC_QUOTE_EXPIRY_DAYS',
    },
    debugAnalytics: {
        format: Boolean,
        default: false,
        env: 'NEXT_PUBLIC_DEBUG_ANALYTICS',
    },
};

export default frontEndConfigSchema;
