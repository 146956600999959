import config from '../config';
import Quote from './api/model/Quote';

interface PaymentPostMessage {
    event: string;
    success?: boolean;
    status?: string;
    message?: string;

    [key: string]: unknown;
}

export const buildReservationPaymentUrl = ({
    quote,
    isDeposit,
    trackingId,
    authOnly,
    gateway,
}: {
    quote: Quote;
    isDeposit?: boolean;
    trackingId?: string;
    authOnly?: boolean;
    gateway: string;
}): string => {
    const returnParams = {
        ref: quote.ReservationReference,
        _ga: trackingId,
    };

    const parsedPaymentUrl = new URL(quote.PaymentURL, config.apiBaseUrl);
    parsedPaymentUrl.searchParams.set('returnTo', new URL('payment-return', window.location.href).href);
    parsedPaymentUrl.searchParams.set('returnUrl', getPaymentCallbackUrl({ ...returnParams, success: 'true' }));
    parsedPaymentUrl.searchParams.set('failedUrl', getPaymentCallbackUrl({ ...returnParams, success: 'false' }));
    parsedPaymentUrl.searchParams.set('deposit', isDeposit ? 'true' : 'false');
    if (authOnly) {
        parsedPaymentUrl.searchParams.set('authOnly', 'true');
    }
    if (gateway) {
        parsedPaymentUrl.searchParams.set('gateway', gateway);
    }
    return parsedPaymentUrl.href;
};

export const getPaymentCallbackUrl = (query?: Record<string, string | unknown>): string => {
    const url = new URL('/obe/direct/payment-callback', window.location.href);
    url.searchParams.set('callback', 'true');
    if (query) {
        Object.keys(query).forEach((key) => {
            url.searchParams.set(key, query[key] as string);
        });
    }
    return url.href;
};

export const parsePaymentDataMessage = (event: MessageEvent): PaymentPostMessage => (event?.data?.sender === 'payment-frame' ? event.data.data : { event: 'unknown', success: false });

export const postPaymentDataMessage = (message: PaymentPostMessage): void => {
    window.top?.postMessage(
        {
            sender: 'payment-frame',
            data: message,
        },
        window.origin
    );
};
