import { FleetCategory } from '@jucy/rentals-api-client/rentals-api-v3/models/FleetCategory';
import { roundCurrency } from '#/lib/roundCurrency';
import { mapFleetTypeSlugToBrand } from '../../../lib';
import { GtmEventItem } from '../types';

const computeOriginalPrice = (discountedPrice: number, discountPercentage: number = 0): number => {
    if (!discountPercentage || !discountedPrice) {
        return discountedPrice;
    }
    const originalPrice = discountedPrice / (1 - discountPercentage);
    return roundCurrency(originalPrice);
};

const computeDiscountedAmount = (discountedPrice: number, discountPercentage: number = 0): number => {
    const originalPrice = computeOriginalPrice(discountedPrice, discountPercentage);
    return roundCurrency(originalPrice - discountedPrice);
};

export const mapFleetCategoryToGtmItem = (fleetCategory: FleetCategory): GtmEventItem => {
    const discountedAmount = computeDiscountedAmount(fleetCategory.dailyRate.value, fleetCategory.discountPercentageApplied);
    return {
        item_id: fleetCategory.code,
        item_name: fleetCategory.name,
        coupon: fleetCategory.campaignCode || undefined,
        discount: discountedAmount ? discountedAmount : undefined,
        item_brand: mapFleetTypeSlugToBrand(fleetCategory.fleetTypeCode),
        item_category: 'fleet-category',
        item_category2: fleetCategory.fleetTypeCode,
        item_variant: fleetCategory.availability,
        price: fleetCategory.dailyRate.value,
        quantity: fleetCategory.rentalDays,
    };
};
