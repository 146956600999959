import ErrorReporter from '#/lib/ErrorReporter';
import { PartialSearchFormValues } from '../components/Forms/TripSearchForm/SearchFormValues';
import config from '../config';
import AccountKeyStore from '../store/AccountKeyStore';
import * as SalesMonitor from './SalesMonitoring';
import { LineItem } from './api/model/LineItem';
import Quote from './api/model/Quote';
import analytics from '#/services/analytics';
import { mergeDateTime } from '@jucy-ui/common';
import { mapFleetTypeSlugToBrand } from '#/services/analytics/lib/mapFleetTypeSlugToBrand';
import { InsuranceProductLine } from '@jucy/rentals-api-client/rentals-api-v3/models/InsuranceProductLine';
import { FleetCategory } from '@jucy/rentals-api-client/rentals-api-v3/models/FleetCategory';
import { SecondaryProduct } from '@jucy/rentals-api-client/rentals-api-v3/models/SecondaryProduct';
import { InsuranceProduct } from '@jucy/rentals-api-client/rentals-api-v3/models/InsuranceProduct';
import bookingFunnelAnalyticsStore from '#/store/BookingFunnelAnalyticsStore';
import { Booking } from '@jucy/rentals-api-client/rentals-api-v3/models/Booking';
import { mapAnalyticsJucyBookingFunnelTripToGtmItem } from '#/services/analytics/plugins/analyticsJucyEcommerce';

if (typeof window !== 'undefined' && !window.dataLayer) {
    window.dataLayer = [];
}

export function logTracking(...args: unknown[]) {
    if (config.logTracking) {
        // eslint-disable-next-line prefer-spread,no-console
        console.log.apply(console, args);
    }
}

export const isLineItem = (item: unknown): item is LineItem => (item as LineItem).ProductCode !== undefined;

export default class Events {
    static trackBookingInitiated(eventData: PartialSearchFormValues) {
        try {
            const trip = Events.mapTrip(eventData);
            if (trip) {
                bookingFunnelAnalyticsStore.startFunnel(trip);
            }
        } catch (err) {
            ErrorReporter.captureError(err);
        }
    }

    static trackFleetCategorySelected(
        data: PartialSearchFormValues & {
            fleetCategory: FleetCategory;
            previousFleetCategory?: FleetCategory;
            listId?: string;
            listName?: string;
        }
    ) {
        try {
            const trip = Events.mapTrip(data);
            if (trip) {
                analytics.plugins.ecommerce.fleetCategorySelected({
                    ...trip,
                    listId: data.listId,
                    listName: data.listName,
                    fleetCategory: data.fleetCategory,
                    previousFleetCategory: data.previousFleetCategory,
                });
            }
        } catch (err) {
            ErrorReporter.captureError(err);
        }
    }

    static trackAddInsuranceOption(
        data: PartialSearchFormValues & {
            current: InsuranceProductLine;
            previous?: InsuranceProductLine;
        }
    ) {
        try {
            const trip = Events.mapTrip(data);
            if (trip) {
                analytics.plugins.ecommerce.excessSelected({
                    ...trip,
                    current: data.current,
                    previous: data.previous,
                });
            }
        } catch (err) {
            ErrorReporter.captureError(err);
        }
    }

    static trackListProducts(
        data: PartialSearchFormValues & {
            items: (SecondaryProduct | InsuranceProduct)[];
            listId: string;
            listName: string;
        }
    ) {
        try {
            const trip = Events.mapTrip(data);
            if (trip) {
                analytics.plugins.ecommerce.trackEcommerce('view_item_list', {
                    ...mapAnalyticsJucyBookingFunnelTripToGtmItem(trip),
                    region: trip.region,
                    item_list_id: data.listId,
                    item_list_name: data.listName,
                    currency: data.items.find((excess) => excess.price.currencyCode)?.price.currencyCode,
                    items: data.items.map((item) => ({
                        item_id: item.productCode || item.productId,
                        item_name: item.name,
                        item_category: item.category,
                        price: item.price.value,
                        currency: item.price.currencyCode,
                        brand: trip.brand,
                        location_id: data.pickUpLocation?.SiteCode,
                    })),
                });
            }
        } catch (err) {
            ErrorReporter.captureError(err);
        }
    }

    static trackExtrasCartEvent(
        data: PartialSearchFormValues & {
            action: 'add' | 'remove';
            product: SecondaryProduct | InsuranceProduct;
            delta: number;
        }
    ) {
        try {
            const trip = Events.mapTrip(data);
            if (trip) {
                analytics.plugins.ecommerce.updateExtra({
                    ...trip,
                    action: data.action,
                    product: data.product,
                });
            }
        } catch (err) {
            console.error(err);
            ErrorReporter.captureError(err);
        }
    }

    static trackPaymentOptionSelected(
        data: PartialSearchFormValues & {
            paymentOption: string;
        }
    ) {
        try {
            const trip = Events.mapTrip(data);
            if (trip) {
                analytics.plugins.ecommerce.updatePaymentOption({
                    ...trip,
                    paymentOption: data.paymentOption,
                });
            }
        } catch (err) {
            ErrorReporter.captureError(err);
        }
    }

    static trackCheckout(data: { booking: Booking }) {
        try {
            analytics.plugins.ecommerce.checkout(data);
        } catch (err) {
            ErrorReporter.captureError(err);
        }
    }

    static trackPayment(data: { booking: Booking; paymentOption: string; paymentAmount: number }) {
        analytics.plugins.ecommerce.paymentSubmitted(data);
    }

    static trackPurchase(quote: Quote, _accountId?: string) {
        logTracking('send purchase event');

        if (localStorage.getItem(`purchase-tracked-${quote.ReservationReference}`) === 'true') {
            logTracking('purchase event already fired');
            return;
        }
        localStorage.setItem(`purchase-tracked-${quote.ReservationReference}`, 'true');

        if (!AccountKeyStore.trackingEnabled) {
            logTracking('Events disabled for account');
            return;
        }

        SalesMonitor.confirmPurchase(quote, AccountKeyStore?.user?.type || 'direct');
    }

    static trackProductImpressionList(
        data: PartialSearchFormValues & {
            availableFleetCategories: FleetCategory[];
            listId?: string;
            listName?: string;
        }
    ) {
        try {
            const trip = Events.mapTrip(data);
            if (trip) {
                analytics.plugins.ecommerce.trackProductImpressionList({
                    ...trip,
                    listId: data.listId,
                    listName: data.listName,
                    fleetCategories: data.availableFleetCategories,
                });
            }
        } catch (err) {
            ErrorReporter.captureError(err);
        }
    }

    static trackProductImpression(
        data: PartialSearchFormValues & {
            fleetCategory: FleetCategory;
            listId?: string;
            listName?: string;
        }
    ) {
        try {
            const trip = Events.mapTrip(data);
            if (trip) {
                analytics.plugins.ecommerce.trackProductImpression({
                    ...trip,
                    listId: data.listId,
                    listName: data.listName,
                    fleetCategory: data.fleetCategory,
                });
            }
        } catch (err) {
            ErrorReporter.captureError(err);
        }
    }

    private static mapTrip(searchParams: PartialSearchFormValues) {
        const pickUpDate = mergeDateTime(searchParams.pickUpDate, searchParams.pickUpTime);
        const dropOffDate = mergeDateTime(searchParams.dropOffDate, searchParams.dropOffTime);
        if (searchParams.fleetType && searchParams.pickUpLocation?.SiteCode && pickUpDate && searchParams.dropOffLocation?.SiteCode && dropOffDate) {
            return {
                fleetType: searchParams.fleetType.slug,
                pickUpLocation: searchParams.pickUpLocation?.SiteCode,
                pickUpDate,
                dropOffLocation: searchParams.dropOffLocation?.SiteCode,
                dropOffDate: dropOffDate,
                coupon: searchParams.promoCode,
                brand: mapFleetTypeSlugToBrand(searchParams.fleetType.slug),
                region: searchParams.pickUpLocation?.CountryCode,
            };
        }
        return undefined;
    }
}
