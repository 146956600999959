import { ProductLine } from '@jucy/rentals-api-client/rentals-api-v3/models/ProductLine';
import { GtmEventItem } from '../types';

export const mapProductLineToGtmItem = (item: ProductLine): GtmEventItem => ({
    item_id: item.productCode || item.productId,
    item_name: item.name,
    item_category: item.category,
    price: item.price.value,
    currency: item.price.currencyCode,
    quantity: item.productType === 'Daily' ? item.rentalDays * item.qty : item.qty,
});
