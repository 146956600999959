import { SecondaryProductItem } from '@jucy/rentals-api-client';
import { BookingStatus } from '@jucy/rentals-api-client/rentals-api-v3';
import { parseISO } from 'date-fns';
import { computed, isObservableArray, makeAutoObservable, observable, toJS } from 'mobx';
import { FleetType } from '../../../services';
import FleetTypesStore from '../../../store/FleetTypesStore';
import ProductStore from '../../../store/ProductStore';
import SitesStore from '../../../store/SitesStore';
import { isDefaultHirerName } from '../../isDefaultHirerName';
import FleetCategoryAvailability from './FleetCategoryAvailability';
import { default as HirerDetailsClass } from './HirerDetails';
import InsuranceProduct from './InsuranceProduct';
import { LineItem } from './LineItem';
import Money from './Money';
import Product from './Product';
import ProductGroup from './ProductGroup';
import { Site } from './Site';
import type { BookingPaymentInfo } from '@jucy-ui/common';
import { QuoteStatus } from '@jucy/rentals-api-client/rentals-api-v3/models/QuoteStatus';
import { InsuranceProductLineCategoryEnum } from '@jucy/rentals-api-client/rentals-api-v3/models/InsuranceProductLine';

class Quote {
    @observable QuoteId = '';
    @observable ReservationId = '';
    @observable ReservationReference = '';

    @observable OnlineLogin = '';
    @observable QuoteName = '';
    @observable PickUpLocationId = '';
    @observable PickUpLocationCode = '';
    @observable DropOffLocationId = '';
    @observable DropOffLocationCode = '';
    @observable PickUpDate = '';
    @observable DropOffDate = '';
    @observable RentalDays = 0;
    @observable FleetCategory = new FleetCategoryAvailability({});
    @observable TotalPrice = new Money();
    @observable AgentCommission = new Money();
    @observable AgentCollects = new Money();
    @observable AgentBalanceDue = new Money();
    @observable BalanceDue = new Money();
    @observable Lines: LineItem[] = [];
    @observable InsuranceProducts: ProductGroup<InsuranceProduct>[] = [];
    @observable SecondaryProducts: ProductGroup<Product>[] = [];
    @observable CCSurchargePercentage = 0;
    @observable AgentVoucherReference = '';
    @observable AgentName?: string;
    @observable HirerDetails = new HirerDetailsClass();
    @observable AdditionalDrivers = [];
    @observable DateCreated = '';
    @observable Status: BookingStatus | QuoteStatus = QuoteStatus.Quote;
    @observable PaymentURL = '';
    @observable PaymentToken = '';
    @observable deal?: { type: string };
    @observable paymentOptionId?: string = undefined;
    @observable AgentPaymentType = 0;
    @observable BookingStatus = '';
    @observable PickUpLocationTimeZone = '';
    @observable acceptedTerms = false;
    @observable payments?: BookingPaymentInfo[] = [];
    constructor(props?: Partial<Quote>) {
        makeAutoObservable(this);
        Object.assign(this, props);
    }

    @computed get id() {
        return this.QuoteId;
    }

    @computed get ReservationDisplayReference() {
        return `#${this.PickUpLocationCode}-${this.ReservationId}`;
    }

    @computed get AvailableProducts() {
        const products: Product[] = [];
        [this.InsuranceProducts, this.SecondaryProducts].forEach((productGroups) => {
            productGroups.forEach((productGroup) => {
                productGroup.Items.forEach((product) => {
                    products.push(product);
                });
            });
        });
        return products;
    }

    @computed get InsuranceCodes() {
        return this.InsuranceProducts[0].Items.map((i) => i.ProductCode).filter((x) => x);
    }

    @computed get InsuranceLine() {
        const product = this.ProductLines.find((l) => l.ProductCode && !l.IsExcluded && this.InsuranceCodes.includes(l.ProductCode));
        return product ? new LineItem(product) : null;
    }

    @computed get v3InsuranceLine() {
        const insuranceLine = this.InsuranceLine?.asV3ProductLine();
        const insuranceProduct = insuranceLine ? this.InsuranceProducts[0].Items.find((item) => item.ProductCode === insuranceLine.productCode)?.asV3InsuranceProduct() : undefined;

        return insuranceLine && insuranceProduct
            ? {
                  ...insuranceLine,
                  excessType: insuranceProduct.excessType,
                  excess: insuranceProduct.excess,
                  bond: insuranceProduct.bond,
                  category: InsuranceProductLineCategoryEnum.InsuranceFee,
              }
            : undefined;
    }

    @computed get ExtrasLines() {
        return this.ProductLines.filter((l) => !this.InsuranceCodes.includes(l.ProductCode) && !l.IsMandatory);
    }

    @computed get BundledItemsLines() {
        return (
            this.ProductLines.filter((l) => l.AssociatedProductCode && !l.IsExcluded).map((l) => ({
                isExcluded: l.IsExcluded,
                sortOrder: l.SortOrder,
                productCode: l.ProductCode,
                name: l.Name,
                category: l.Category,
                isMandatory: l.IsMandatory,
                description: l.Description,
                price: l.Price,
                productType: l.ProductType,
                productId: l.ProductId,
                isPercentageOnTotalCost: false,
                allowMultiple: l.AllowMultiple,
                commissionable: l.Commissionable,
                associatedProductCode: l.AssociatedProductCode,
                rentalDays: l.RentalDays,
                numberOfDaysCalculated: l.NumberOfDaysCalculated,
                maxPrice: l.MaxPrice,
                qty: l.Qty,
                total: l.Total,
            })) || []
        );
    }

    @computed get CommisionableLines() {
        return this.ProductLines.filter((l) => l && !l.IsExcluded && l.Commissionable);
    }

    @computed get MandatoryLines() {
        return this.ProductLines.filter((l) => l.IsMandatory);
    }

    @computed get HireFee() {
        return this.FleetCategory.Total;
    }

    @computed get FleetType() {
        return this.FleetCategory.fleetType || FleetTypesStore.getDefault();
    }

    @computed get PickUpSite(): Site | undefined {
        return SitesStore.getSiteByCode(this.PickUpLocationCode) || undefined;
    }

    @computed get DropOffSite(): Site | undefined {
        return SitesStore.getSiteByCode(this.DropOffLocationCode) || undefined;
    }

    @computed get PickUpDateTime() {
        return parseISO(this.PickUpDate);
    }

    @computed get DropOffDateDateTime() {
        return parseISO(this.DropOffDate);
    }

    @computed get isRelocationDeal() {
        return this.deal && this.deal.type === 'vehicle-relocation';
    }

    @computed get ProductLines() {
        const result: Product[] = [];
        this.Lines.map((l) => toJS(l)).forEach((line) => {
            const existingLine = result.find((l) => l.ProductId === line.ProductId);
            const allowMultiple = line.AllowMultiple;
            let qty = allowMultiple && (line.Qty || line.Qty === 0) ? line.Qty : 1;
            if (line.ProductType === 'Daily' && line.Qty === line.RentalDays) {
                qty = 1;
            }
            if (existingLine) {
                existingLine.Qty = allowMultiple ? existingLine.Qty + qty : 1;
                existingLine.Total.value = existingLine.Total.Value + line.Total.Value;
            } else {
                result.push(
                    new Product({
                        ...line,
                        Total: line.Total || { ...line.Price },
                        Qty: qty,
                    })
                );
            }
        });

        return result;
    }

    @computed get PayAtBranchLines() {
        return this.ProductLines.filter((l) => !(l.IsExcluded || l.IsMandatory || ['InsuranceFee', 'OtherFee'].includes(l.Category)));
    }

    @computed get isRequest() {
        return this.FleetCategory && this.FleetCategory.Availability === 'OnRequest';
    }

    @computed get isConfirmed() {
        return this.Status === 'confirmed';
    }

    @computed get isComplete() {
        return this.Status === 'returned';
    }

    @computed get isOnRoad() {
        return this.Status === 'on-road';
    }

    @computed get isReadOnly() {
        return this.isComplete;
    }

    @computed get isQuote() {
        return this.Status === 'quote';
    }

    @computed get isNew() {
        const hirerDetails = toJS(this.HirerDetails);
        return Boolean(!hirerDetails || !hirerDetails.FirstName || !hirerDetails.FirstName || isDefaultHirerName(hirerDetails.FirstName, hirerDetails.LastName));
    }

    @computed get isDefaultHirer() {
        return isDefaultHirerName(this.HirerDetails.Email);
    }

    @computed get CustomerBalanceDue() {
        const res = this.TotalPrice.Value - this.AgentCollects.Value;
        return new Money({
            currencyCode: this.TotalPrice.currencyCode,
            value: res,
        });
    }

    @computed get depositAmount() {
        const fleetType = this.FleetType;
        const depositPercent = fleetType ? fleetType.depositPercent / 100 : null;
        const totalAmount = this.calculatedTotal(true).value;
        const depositAmount = depositPercent ? totalAmount * depositPercent : totalAmount;
        const depositCCFeeAmount = this.CCSurchargePercentage ? depositAmount * this.CCSurchargePercentage : 0;
        return new Money({
            value: depositAmount + depositCCFeeAmount,
            currencyCode: this.TotalPrice.CurrencyCode,
        });
    }

    @computed get fullPaymentAmount() {
        const fullAmount = this.calculatedTotal(true).value;
        const fullCCFeeAmount = this.CCSurchargePercentage ? fullAmount * this.CCSurchargePercentage : 0;
        return new Money({
            value: fullAmount + fullCCFeeAmount,
            currencyCode: this.TotalPrice.CurrencyCode,
        });
    }

    @computed get remainingAmount() {
        return new Money({
            value: this.calculatedTotal().value - this.depositAmount.value,
            currencyCode: this.TotalPrice.CurrencyCode,
        });
    }

    @computed get ccFeeLineItem() {
        return this.Lines.find((o) => o.ProductCode === 'CCFee');
    }

    @computed get totalDiscountAmount() {
        const discountTotal = this.Lines.filter((l) => l.Total.Value < 0).reduce((res, i) => res + i.Total.Value, 0) || 0;
        return discountTotal < 0 ? discountTotal * -1 : 0;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    static fromApi(apiQuote: any) {
        const { FleetCategory, HirerDetails, ...quoteData } = apiQuote;
        const pickupSite = SitesStore.getSiteByCode(apiQuote.PickUpLocationCode);
        return Quote.fromPlain({
            ...quoteData,
            HirerDetails,
            FleetCategory: FleetCategoryAvailability.fromApi({
                apiFleetCategory: FleetCategory,
                fleetType: FleetTypesStore.getFleetTypeById(FleetCategory.Type) as FleetType,
                catalogData: ProductStore.getProductByCode(FleetCategory.CategoryCode, pickupSite?.CountryCode),
            }),
        });
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    static fromPlain(plainQuote: any) {
        let result;
        if (plainQuote && plainQuote.FleetCategory) {
            const { FleetCategory, HirerDetails, ...quoteData } = plainQuote;
            result = new Quote({
                ...quoteData,
                FleetCategory: FleetCategoryAvailability.fromPlain(FleetCategory),
                HirerDetails: HirerDetailsClass.fromApi(HirerDetails),
                SecondaryProducts: (plainQuote.SecondaryProducts || []).map((g: ProductGroup) => new ProductGroup({ ...g, Items: g.Items.map((i) => new Product(i)) })),
                InsuranceProducts: (plainQuote.InsuranceProducts || []).map((g: ProductGroup) => new ProductGroup({ ...g, Items: g.Items.map((i) => new InsuranceProduct(i)) })),
            });
        }
        return result;
    }

    updateLinePrices(prices: (SecondaryProductItem & { quantity: number })[]) {
        if (isObservableArray(this.Lines)) {
            this.Lines.replace(
                this.Lines.filter((l) => l.IsMandatory).concat(
                    prices
                        .map((productPrice) => {
                            let product: Partial<Product | LineItem> | undefined = this.AvailableProducts.find((prod) => productPrice.id === prod.ProductId);
                            if (!product) {
                                product = this.Lines.find((prod) => productPrice.id === prod.ProductId);
                            }
                            if (!product) {
                                product = {
                                    Name: productPrice.name || '',
                                    ProductId: productPrice.id || '',
                                    IsMandatory: false,
                                };
                            }
                            const line = new LineItem({
                                ...product,
                                Name: product?.Name || productPrice.name,
                                IsMandatory: product?.IsMandatory,
                                Price: new Money({
                                    currencyCode: productPrice?.price?.currencyCode,
                                    value: productPrice?.price?.value || 0,
                                }),
                                RentalDays: productPrice.rentalDays,
                                NumberOfDaysCalculated: productPrice.rentalDays,
                                MaxPrice: productPrice.maxPrice
                                    ? new Money({
                                          currencyCode: productPrice.maxPrice.currencyCode,
                                          value: productPrice.maxPrice.value || 0,
                                      })
                                    : undefined,
                                Total: productPrice.total
                                    ? new Money({
                                          currencyCode: productPrice.total.currencyCode,
                                          value: productPrice.total.value || 0,
                                      })
                                    : undefined,
                                IsExcluded: productPrice.isExcluded,
                                SortOrder: productPrice.sortOrder,
                                Qty: productPrice.quantity,
                                AssociatedProductCode: productPrice.associatedProductCode,
                            });
                            return line;
                        })
                        .filter((l) => !l.IsMandatory)
                )
            );
        }
        return this.Lines;
    }

    calculatedTotal(excludeCCFee = false) {
        const total = this.ProductLines.reduce((total, line) => {
            const value = line.Total.value || line.Total.Value || 0;
            if (!line.IsExcluded && line.Qty > 0 && (!excludeCCFee || line.ProductCode !== 'CCFee')) {
                total += value;
            }
            return total;
        }, this.HireFee?.Value || 0);
        return {
            value: total,
            currencyCode: this.TotalPrice.CurrencyCode,
        };
    }

    toPlain() {
        return toJS(this);
    }
}

export default Quote;
