import { BookingFunnelStepName } from './BookingFunnelStepName';

export const bookingFunnelSteps: BookingFunnelStepName[] = [
    'bookingInitiated',
    'availabilityList',
    'excessList',
    'extrasList',
    'paymentList',
    'hirerDetails',
    'paymentDetails',
    'bookingComplete',
];
