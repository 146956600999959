import { AnalyticsJucyBookingFunnelTrip } from '../../analyticsJucyBookingFunnel';
import { format } from 'date-fns';

export const mapAnalyticsJucyBookingFunnelTripToGtmItem = (props: AnalyticsJucyBookingFunnelTrip) => ({
    fleetType: props.fleetType,
    pickUpLocation: props.pickUpLocation,
    pickUpDate: format(props.pickUpDate, 'yyyy-MM-dd'),
    pickUpTime: format(props.pickUpDate, 'HH:mm'),
    dropOffLocation: props.dropOffLocation,
    dropOffDate: format(props.dropOffDate, 'yyyy-MM-dd'),
    dropOffTime: format(props.dropOffDate, 'HH:mm'),
    coupon: props.coupon,
    brand: props.brand,
    region: props.region,
});
