import { AnalyticsPlugin, AnalyticsPluginArgs } from 'analytics';
import AccountKeyStore from '#/store/AccountKeyStore';

const pluginName = 'analyticsJucyObe';
export const analyticsJucyObe = (pluginConfig: { debug?: boolean } = {}): AnalyticsPlugin => ({
    name: pluginName,
    config: pluginConfig,
    'track:datadog': ({ payload }: AnalyticsPluginArgs) => ({
        ...payload,
        properties: {
            ...payload.properties,
            accountType: AccountKeyStore?.user?.type || 'direct',
        },
    }),
});
