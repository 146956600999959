import { AnalyticsJucyBookingFunnelTrip, BookingFunnelEventParams } from './types';
import { mapAnalyticsJucyBookingFunnelTripToGtmItem } from '../analyticsJucyEcommerce';
import { AnalyticsInstanceExtended, Plugin } from 'analytics';

export type AnalyticsJucyBookingFunnelPlugin = Plugin<{
    trackProgress: (event: AnalyticsJucyBookingFunnelTrip & BookingFunnelEventParams) => void;
}>;

const pluginName = 'bookingFunnel';
export const analyticsJucyBookingFunnel = (pluginConfig: AnalyticsJucyBookingFunnelPlugin['config'] = {}): AnalyticsJucyBookingFunnelPlugin => ({
    name: pluginName,
    config: pluginConfig,
    initialize: () => {
        if (typeof window === 'undefined') {
            return;
        }
    },
    methods: {
        trackProgress(this: { instance: AnalyticsInstanceExtended }, params) {
            if (params.step === 'bookingInitiated') {
                const eventData = {
                    step: 1,
                    ...mapAnalyticsJucyBookingFunnelTripToGtmItem(params),
                };
                if (pluginConfig.debug) {
                    // eslint-disable-next-line no-console
                    console.log(`[${pluginName}] trackProgress ${params.step}`, eventData);
                }
                return this.instance.track(params.step, eventData);
            }

            if (params.step === 'availabilityList') {
                const eventData = {
                    step: 2,
                    ...mapAnalyticsJucyBookingFunnelTripToGtmItem(params),
                    fleetCategories: params.fleetCategories.map((fleetCategory) => `${fleetCategory.code}-${fleetCategory.availability}-${fleetCategory.dailyRate.value}`).join(','),
                };
                if (pluginConfig.debug) {
                    // eslint-disable-next-line no-console
                    console.log(`[${pluginName}] trackProgress ${params.step}`, eventData);
                }
                return this.instance.track(params.step, eventData);
            }

            if (params.step === 'excessList') {
                const eventData = {
                    step: 3,
                    ...mapAnalyticsJucyBookingFunnelTripToGtmItem(params),
                    excessProducts: params.excessProducts.map((excessProduct) => `${excessProduct.productCode}-${excessProduct.price.value}`).join(','),
                };
                if (pluginConfig.debug) {
                    // eslint-disable-next-line no-console
                    console.log(`[${pluginName}] trackProgress ${params.step}`, eventData);
                }
                return this.instance.track(params.step, eventData);
            }

            if (params.step === 'extrasList') {
                const eventData = {
                    step: 4,
                    ...mapAnalyticsJucyBookingFunnelTripToGtmItem(params),
                    extraProducts: params.extraProducts.map((extraProduct) => `${extraProduct.productCode}-${extraProduct.price.value}`).join(','),
                };
                if (pluginConfig.debug) {
                    // eslint-disable-next-line no-console
                    console.log(`[${pluginName}] trackProgress ${params.step}`, eventData);
                }
                return this.instance.track(params.step, eventData);
            }

            if (params.step === 'paymentList') {
                const eventData = {
                    step: 5,
                    ...mapAnalyticsJucyBookingFunnelTripToGtmItem(params),
                    paymentTypes: params.paymentOptions.join(','),
                };
                if (pluginConfig.debug) {
                    // eslint-disable-next-line no-console
                    console.log(`[${pluginName}] trackProgress ${params.step}`, eventData);
                }
                return this.instance.track(params.step, eventData);
            }

            if (params.step === 'hirerDetails') {
                const eventData = {
                    step: 6,
                    ...mapAnalyticsJucyBookingFunnelTripToGtmItem(params),
                };
                if (pluginConfig.debug) {
                    // eslint-disable-next-line no-console
                    console.log(`[${pluginName}] trackProgress ${params.step}`, eventData);
                }
                return this.instance.track(params.step, eventData);
            }

            if (params.step === 'paymentDetails') {
                const eventData = {
                    step: 7,
                    ...mapAnalyticsJucyBookingFunnelTripToGtmItem(params),
                };
                if (pluginConfig.debug) {
                    // eslint-disable-next-line no-console
                    console.log(`[${pluginName}] trackProgress ${params.step}`, eventData);
                }
                return this.instance.track(params.step, eventData);
            }

            if (params.step === 'bookingComplete') {
                const eventData = {
                    step: 8,
                    ...mapAnalyticsJucyBookingFunnelTripToGtmItem(params),
                    reservationNumber: params.booking.reservationReference,
                };
                if (pluginConfig.debug) {
                    // eslint-disable-next-line no-console
                    console.log(`[${pluginName}] trackProgress ${params.step}`, eventData);
                }
                return this.instance.track(params.step, eventData);
            }

            return new Promise((resolve) => resolve(undefined));
        },
    },
});
