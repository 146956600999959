import { makeAutoObservable, runInAction, toJS } from 'mobx';
import { searchUidFromFormValues } from '@jucy-ui/components/SearchForm/lib/searchUidFromFormValues';
import { makePersistable } from 'mobx-persist-store';
import analytics from '#/services/analytics';
import {
    AnalyticsJucyBookingFunnelTrip,
    BookingFunnelEventParams,
    BookingFunnelProgress,
    BookingFunnelStepName,
    bookingFunnelSteps,
    defaultFunnelProgress,
} from '#/services/analytics/plugins/analyticsJucyBookingFunnel';
import ErrorReporter from '#/lib/ErrorReporter';

class BookingFunnelAnalyticsStore {
    public currentFunnel: BookingFunnelProgress = { ...defaultFunnelProgress };
    public currentTrp?: AnalyticsJucyBookingFunnelTrip = undefined;
    public funnels: Record<string, BookingFunnelProgress> = {};
    public currentSearchUid: string | null = null; // Keep track of the current search

    constructor() {
        makeAutoObservable(this);
        if (typeof window !== 'undefined') {
            makePersistable(this, {
                name: 'BookingFunnelAnalyticsStore',
                properties: [
                    'currentFunnel',
                    'funnels',
                    'currentSearchUid',
                    {
                        key: 'currentTrp',
                        serialize: (value: AnalyticsJucyBookingFunnelTrip | undefined) => value,
                        deserialize: (value): AnalyticsJucyBookingFunnelTrip | undefined => {
                            try {
                                return {
                                    ...value,
                                    pickUpDate: typeof value.pickUpDate === 'string' ? new Date(value.pickUpDate) : value.pickUpDate,
                                    dropOffDate: typeof value.dropOffDate === 'string' ? new Date(value.dropOffDate) : undefined,
                                };
                            } catch (e) {
                                ErrorReporter.captureError(e);
                                return undefined;
                            }
                        },
                    },
                ],
                storage: window.sessionStorage,
            }).then();
        }
    }

    startFunnel(values: AnalyticsJucyBookingFunnelTrip) {
        const searchUid = searchUidFromFormValues(values);
        runInAction(() => {
            this.currentSearchUid = searchUid; // Store the search UID
            this.currentTrp = values;
            if (this.funnels[searchUid]) {
                this.currentFunnel = this.funnels[searchUid];
            } else {
                this.currentFunnel = { ...defaultFunnelProgress };
                this.funnels[searchUid] = this.currentFunnel;
            }
            this.markStepComplete({ step: 'bookingInitiated', ...values });
        });
    }

    markStepComplete(params: BookingFunnelEventParams) {
        if (!this.currentSearchUid) {
            console.warn('markStepComplete called without a currentSearchUid.  This indicates a problem in the booking flow.  Step was:', params.step);
            return;
        }

        const stepIndex = bookingFunnelSteps.indexOf(params.step);

        if (stepIndex <= -1) {
            console.warn(`markStepComplete called with invalid step: ${params.step}.  This indicates a problem in the booking flow.`);
            return;
        }

        runInAction(() => {
            // Set all steps up to and including the current step to true
            for (let i = 0; i <= stepIndex; i++) {
                if (!this.currentFunnel[bookingFunnelSteps[i]]) {
                    try {
                        // If the current step is not already marked as complete, track it in analytics
                        analytics.plugins.bookingFunnel.trackProgress({
                            ...params,
                            ...(toJS(this.currentTrp) as AnalyticsJucyBookingFunnelTrip),
                        });
                    } catch (e) {
                        ErrorReporter.captureError(e);
                    }
                }
                this.currentFunnel[bookingFunnelSteps[i]] = true;
            }
            if (this.currentSearchUid) {
                this.funnels[this.currentSearchUid] = { ...this.currentFunnel }; // Update the funnel in the map, use spread
            }
        });
    }

    resetFrom(step: BookingFunnelStepName) {
        const stepIndex = bookingFunnelSteps.indexOf(step);

        if (stepIndex <= -1) {
            console.warn(`markStepComplete called with invalid step: ${step}.  This indicates a problem in the booking flow.`);
            return;
        }
        runInAction(() => {
            for (let i = stepIndex; i <= bookingFunnelSteps.length - 1; i++) {
                this.currentFunnel[bookingFunnelSteps[i]] = false;
            }
        });
    }

    resetFunnel() {
        runInAction(() => {
            if (this.currentSearchUid) {
                delete this.funnels[this.currentSearchUid];
            }
            this.currentSearchUid = null;
            this.currentFunnel = { ...defaultFunnelProgress };
        });
    }

    getFunnelProgress(searchUid: string): BookingFunnelProgress | undefined {
        return this.funnels[searchUid];
    }
}

const bookingFunnelAnalyticsStore = new BookingFunnelAnalyticsStore();
export default bookingFunnelAnalyticsStore;
