import Analytics, { AnalyticsInstanceExtended } from 'analytics';
import { analyticsJucyBookingFunnel } from './plugins/analyticsJucyBookingFunnel';
import { analyticsJucyEcommerce } from './plugins/analyticsJucyEcommerce';
import { analyticsJucyGtm } from './plugins/analyticsJucyGtm';
import { analyticsJucyInteractions } from './plugins/analyticsJucyInteraction';
import { analyticsJucyDatadog } from './plugins/analyticsJucyDatadog';
import { analyticsJucyObe } from '#/services/analytics/plugins/analyticsJucyObe';
import config from '#/config';

const sharedConfig = { debug: config.debugAnalytics };
const analytics = Analytics({
    ...sharedConfig,
    app: 'oneida',
    plugins: [
        analyticsJucyGtm({ ...sharedConfig }),
        analyticsJucyInteractions({ ...sharedConfig }),
        analyticsJucyEcommerce({ ...sharedConfig }),
        analyticsJucyBookingFunnel({ ...sharedConfig }),
        analyticsJucyDatadog({ ...sharedConfig }),
        analyticsJucyObe(),
    ],
}) as AnalyticsInstanceExtended;

export default analytics;
