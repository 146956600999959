import { AnalyticsPlugin, AnalyticsPluginArgs } from 'analytics';
import { hashIfEmail } from '../lib';

const pluginName = 'google-tag-manager';

const pushToDataLayer = (data: Record<string, unknown>) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(data);
};

export const analyticsJucyGtm = (pluginConfig: { debug?: boolean } = {}): AnalyticsPlugin => ({
    name: pluginName,
    config: pluginConfig,
    initialize: () => {
        if (typeof window === 'undefined') {
            return;
        }
        window.dataLayer = window.dataLayer || [];
    },
    page: ({ payload, config }: AnalyticsPluginArgs) => {
        if (config.debug) {
            // eslint-disable-next-line no-console
            console.log(`[${pluginName}] page`, payload.properties);
        }
        pushToDataLayer(payload.properties);
    },
    track: ({ payload, config }: AnalyticsPluginArgs) => {
        const { anonymousId, userId, properties } = payload;
        const formattedPayload: Record<string, unknown> = {
            event: payload.event,
            ...properties,
        };
        if (userId) {
            formattedPayload.userId = hashIfEmail(userId);
        }
        if (anonymousId) {
            formattedPayload.anonymousId = anonymousId;
        }
        if (!properties.category) {
            formattedPayload.category = 'All';
        }
        if (config.debug) {
            // eslint-disable-next-line no-console
            console.log(`[${pluginName}] track`, formattedPayload);
        }
        if (formattedPayload.ecommerce) {
            pushToDataLayer({ ecommerce: null });
        }
        pushToDataLayer(formattedPayload);
    },
    loaded: () => typeof window !== 'undefined',
});
