import { AnalyticsInstanceExtended, Plugin } from 'analytics';
import { AnalyticsJucyBookingFunnelTrip } from '../analyticsJucyBookingFunnel';
import { FleetCategory } from '@jucy/rentals-api-client/rentals-api-v3/models/FleetCategory';
import { InsuranceProductLine } from '@jucy/rentals-api-client/rentals-api-v3/models/InsuranceProductLine';
import { mapFleetCategoryToGtmItem, mapProductLineToGtmItem } from './lib';
import { SecondaryProduct } from '@jucy/rentals-api-client/rentals-api-v3/models/SecondaryProduct';
import { InsuranceProduct } from '@jucy/rentals-api-client/rentals-api-v3/models/InsuranceProduct';
import { roundCurrency } from '#/lib/roundCurrency';
import { Booking } from '@jucy/rentals-api-client/rentals-api-v3/models/Booking';
import { mapBookingToGtmEventParams } from './lib/mapBookingToGtmEventParams';
import { GtmEventNames, GtmEventParams } from './types';

export type AnalyticsEcommercePlugin = Plugin<{
    trackEcommerce: (event: GtmEventNames, ecommerceEventData: GtmEventParams, eventData?: GtmEventParams & Record<string, undefined>) => void | Promise<void>;
    trackProductImpression: (
        props: AnalyticsJucyBookingFunnelTrip & {
            listId?: string;
            listName?: string;
            fleetCategory: FleetCategory;
        }
    ) => void | Promise<void>;
    trackProductImpressionList: (
        props: AnalyticsJucyBookingFunnelTrip & {
            listId?: string;
            listName?: string;
            fleetCategories: FleetCategory[];
        }
    ) => void | Promise<void>;
    fleetCategorySelected: (
        props: AnalyticsJucyBookingFunnelTrip & {
            listId?: string;
            listName?: string;
            fleetCategory: FleetCategory;
            previousFleetCategory?: FleetCategory;
        }
    ) => void | Promise<void>;
    excessSelected: (
        props: AnalyticsJucyBookingFunnelTrip & {
            current: InsuranceProductLine;
            previous?: InsuranceProductLine;
        }
    ) => void | Promise<void>;
    updateExtra: (
        props: AnalyticsJucyBookingFunnelTrip & {
            action: 'add' | 'remove';
            product: SecondaryProduct | InsuranceProduct;
        }
    ) => void | Promise<void>;
    updatePaymentOption: (
        props: AnalyticsJucyBookingFunnelTrip & {
            paymentOption: string;
        }
    ) => void | Promise<void>;
    checkout: (props: { booking: Booking; checkoutStep: string | number; checkoutOption: string }) => void | Promise<void>;
    paymentSubmitted: (props: { booking: Booking; checkoutStep: string | number; checkoutOption: string; paymentOption: string }) => void | Promise<void>;
    purchase: (props: { booking: Booking; checkoutStep: string | number; checkoutOption: string }) => void | Promise<void>;
}>;
const pluginName = 'ecommerce';
export const analyticsJucyEcommerce = (pluginConfig: AnalyticsEcommercePlugin['config'] = {}): AnalyticsEcommercePlugin => ({
    name: pluginName,
    config: pluginConfig,
    methods: {
        trackEcommerce(this: { instance: AnalyticsInstanceExtended }, event, ecommerceEventData, eventData) {
            const formattedEventData = {
                ...eventData,
                ecommerce: ecommerceEventData,
            };
            if (pluginConfig.debug) {
                // eslint-disable-next-line no-console
                console.log(`[${pluginName}] trackEcommerce`, event, formattedEventData);
            }
            return this.instance.track(event, formattedEventData);
        },
        trackProductImpressionList(this: { instance: AnalyticsInstanceExtended }, props) {
            const ecommerceEventData = {
                item_list_id: props.listId,
                item_list_name: props.listName,
                currency: props.fleetCategories.find((category) => category.total.currencyCode)?.total.currencyCode,
                items: props.fleetCategories.map((data) => ({
                    ...mapFleetCategoryToGtmItem(data),
                    brand: props.brand,
                    location_id: props.pickUpLocation,
                })),
            };
            if (pluginConfig.debug) {
                // eslint-disable-next-line no-console
                console.log(`[${pluginName}] trackProductImpressionList`, ecommerceEventData);
            }
            return this.instance.plugins.ecommerce.trackEcommerce('view_item_list', ecommerceEventData);
        },
        trackProductImpression(this: { instance: AnalyticsInstanceExtended }, props) {
            const eventData = {
                item_list_id: props.listId,
                item_list_name: props.listName,
                currency: props.fleetCategory?.total.currencyCode,
                value: props.fleetCategory?.total.value,
                items: [
                    {
                        ...mapFleetCategoryToGtmItem(props.fleetCategory),
                        brand: props.brand,
                        location_id: props.pickUpLocation,
                    },
                ],
            };
            if (pluginConfig.debug) {
                // eslint-disable-next-line no-console
                console.log(`[${pluginName}] trackProductImpressionList`, eventData);
            }
            return this.instance.plugins.ecommerce.trackEcommerce('view_item', eventData);
        },
        fleetCategorySelected(this: { instance: AnalyticsInstanceExtended }, props) {
            if (props.previousFleetCategory) {
                const ecommerceRemoveFromCartEventData = {
                    currency: props.previousFleetCategory?.total.currencyCode,
                    value: props.previousFleetCategory?.total.value,
                    items: [
                        {
                            ...mapFleetCategoryToGtmItem(props.previousFleetCategory),
                            brand: props.brand,
                            location_id: props.pickUpLocation,
                        },
                    ],
                };
                if (pluginConfig.debug) {
                    // eslint-disable-next-line no-console
                    console.log(`[${pluginName}] remove_from_cart`, ecommerceRemoveFromCartEventData);
                }
                this.instance.plugins.ecommerce.trackEcommerce('remove_from_cart', ecommerceRemoveFromCartEventData);
            }

            const ecommerceEventData = {
                currency: props.fleetCategory?.total.currencyCode,
                value: props.fleetCategory?.total.value,
                coupon: props.coupon,
                items: [
                    {
                        ...mapFleetCategoryToGtmItem(props.fleetCategory),
                        brand: props.brand,
                        location_id: props.pickUpLocation,
                    },
                ],
            };
            if (pluginConfig.debug) {
                // eslint-disable-next-line no-console
                console.log(`[${pluginName}] add_to_cart`, ecommerceEventData);
            }

            return this.instance.plugins.ecommerce.trackEcommerce('add_to_cart', ecommerceEventData);
        },
        excessSelected(this: { instance: AnalyticsInstanceExtended }, props) {
            if (props.previous) {
                const ecommerceRemoveFromCartEventData = {
                    currency: props.previous?.total.currencyCode,
                    value: props.previous?.total.value,
                    items: [
                        {
                            ...mapProductLineToGtmItem(props.previous),
                            brand: props.brand,
                            location_id: props.pickUpLocation,
                        },
                    ],
                };
                if (pluginConfig.debug) {
                    // eslint-disable-next-line no-console
                    console.log(`[${pluginName}] remove_from_cart`, ecommerceRemoveFromCartEventData);
                }
                this.instance.plugins.ecommerce.trackEcommerce('remove_from_cart', ecommerceRemoveFromCartEventData);
            }

            const ecommerceAddToCartEventData = {
                currency: props.current?.total.currencyCode,
                value: props.current?.total.value,
                items: [
                    {
                        ...mapProductLineToGtmItem(props.current),
                        brand: props.brand,
                        location_id: props.pickUpLocation,
                    },
                ],
            };
            if (pluginConfig.debug) {
                // eslint-disable-next-line no-console
                console.log(`[${pluginName}] add_to_cart`, ecommerceAddToCartEventData);
            }
            return this.instance.plugins.ecommerce.trackEcommerce('add_to_cart', ecommerceAddToCartEventData);
        },

        updateExtra(this: { instance: AnalyticsInstanceExtended }, props) {
            const item = mapProductLineToGtmItem({
                ...props.product,
                total: props.product.price,
                qty: 1,
            });
            const ecommerceEventData = {
                currency: props.product?.price.currencyCode,
                value: (item.quantity && item.price ? roundCurrency(props.product.price.value * item.quantity) : item.price) as number,
                items: [
                    {
                        ...item,
                        brand: props.brand,
                        location_id: props.pickUpLocation,
                    },
                ],
            };
            if (pluginConfig.debug) {
                // eslint-disable-next-line no-console
                console.log(`[${pluginName}] ${props.action}`, ecommerceEventData);
            }
            return this.instance.plugins.ecommerce.trackEcommerce(props.action === 'add' ? 'add_to_cart' : 'remove_from_cart', ecommerceEventData);
        },
        updatePaymentOption(this: { instance: AnalyticsInstanceExtended }, props) {
            this.instance.plugins.interactions.trackInteraction({
                source: 'OBE',
                category: 'payment-option-selected',
                action: 'click',
                label: props.paymentOption,
            });
        },
        checkout(this: { instance: AnalyticsInstanceExtended }, { booking }) {
            const eventData = {
                licenseIssueCountry: booking.hirerDetails?.driversLicence?.country,
                numberOfPeople: booking.hirerDetails?.numberOfPeople || 1,
                region: booking.totalPrice.currencyCode === 'NZD' ? 'nz' : 'au',
            };
            const ecommerceEventData = {
                ...mapBookingToGtmEventParams(booking),
            };
            if (pluginConfig.debug) {
                // eslint-disable-next-line no-console
                console.log(`[${pluginName}] begin_checkout`, ecommerceEventData, eventData);
            }
            return this.instance.plugins.ecommerce.trackEcommerce('begin_checkout', ecommerceEventData, eventData);
        },
        paymentSubmitted(this: { instance: AnalyticsInstanceExtended }, { booking, paymentOption }) {
            const eventData = {
                region: booking.totalPrice.currencyCode === 'NZD' ? 'nz' : 'au',
            };
            const ecommerceEventData = {
                ...mapBookingToGtmEventParams(booking),
                payment_type: paymentOption,
            };
            if (pluginConfig.debug) {
                // eslint-disable-next-line no-console
                console.log(`[${pluginName}] add_payment_info`, ecommerceEventData, eventData);
            }
            return this.instance.plugins.ecommerce.trackEcommerce('add_payment_info', ecommerceEventData, eventData);
        },
        purchase(this: { instance: AnalyticsInstanceExtended }, { booking }) {
            const eventData = {
                licenseIssueCountry: booking.hirerDetails?.driversLicence?.country,
                numberOfPeople: booking.hirerDetails?.numberOfPeople || 1,
                region: booking.totalPrice.currencyCode === 'NZD' ? 'nz' : 'au',
            };
            const ecommerceEventData = {
                ...mapBookingToGtmEventParams(booking),
            };
            if (pluginConfig.debug) {
                // eslint-disable-next-line no-console
                console.log(`[${pluginName}] purchase`, ecommerceEventData);
            }
            return this.instance.plugins.ecommerce.trackEcommerce('purchase', ecommerceEventData, eventData);
        },
    },
});
