import { URLSearchParamsInit } from 'react-router-dom';
import {
    BookingPaymentInfo,
    CreateBookingRequest,
    CurrencyValue,
    EmailQuoteRequest,
    FleetCategory,
    InsuranceProduct,
    InsuranceProductGroup,
    ProductBundle,
    ProductLine,
    SecondaryProduct,
    SecondaryProductGroup,
    TripDetails,
} from '@jucy/rentals-api-client/rentals-api-v3';
import { format } from 'date-fns';
import { isObservableArray, makeAutoObservable, toJS } from 'mobx';
import { PartialSearchFormValues, SearchFormValues } from '../components/Forms/TripSearchForm/SearchFormValues';
import { PaymentOption } from '../components/PaymentOptions/lib';
import { CartItem } from '../lib/CartItem';
import { HirerDetailsSummary } from '../lib/api/model/HirerDetailsSummary';
import InsuranceProduct1 from '../lib/api/model/InsuranceProduct';
import Product from '../lib/api/model/Product';
import { Site } from '../lib/api/model/Site';
import { queryStringToSearchFormValues } from '../lib/queryStringToSearchFormValues';
import { stripEmptyProps } from '../lib/stripEmptyProps';
import { getTotalLineValue } from '../lib/sumLineTotal';
import { FleetType } from '../services';
import FleetTypesStore from '../store/FleetTypesStore';
import paymentStore from '../store/PaymentStore';
import { ObeProductLine } from './ObeProductLine';
import { UserMode } from './UserMode';
import { BookingStatus } from '#/lib/api/model/BookingStatus';
import config from '#/config';
import { getPaymentCallbackUrl } from '#/lib/payments';
import { InsuranceProductLineCategoryEnum } from '@jucy/rentals-api-client/rentals-api-v3/models/InsuranceProductLine';
import { AnalyticsJucyBookingFunnelTrip } from '#/services/analytics/plugins/analyticsJucyBookingFunnel';
import { mapFleetTypeSlugToBrand } from '#/services/analytics/lib/mapFleetTypeSlugToBrand';
import { BookingFromJSON } from '@jucy/rentals-api-client/rentals-api-v3/models/Booking';

export const getBookingCartTotal = (cart: BookingCart): number => {
    const rateTotal = cart.fleetCategory?.total.value || 0;
    const mandatoryTotal = cart.mandatoryItems?.filter((l) => !l.isExcluded).reduce((acc, cur) => acc + cur.total.value, 0) || 0;
    const excessTotal = cart.selectedExcess?.total.value || 0;
    const extrasTotal = cart.selectedFixedExtras?.filter((l) => !l.isExcluded).reduce((acc, cur) => acc + getTotalLineValue(cur, 0, rateTotal), 0) || 0;
    const total = rateTotal + mandatoryTotal + excessTotal + extrasTotal;
    const percentageExtrasTotal = cart.selectedPercentageExtras?.filter((l) => !l.isExcluded).reduce((acc, cur) => acc + getTotalLineValue(cur, total, rateTotal), 0) || 0;
    return total + percentageExtrasTotal;
};

export const bookingCartToCreateBookingRequest = (bookingCart: BookingCart): Omit<CreateBookingRequest, 'type'> => ({
    pickUpLocation: bookingCart.pickUpLocation?.SiteCode as string,
    dropOffLocation: bookingCart.dropOffLocation?.SiteCode as string,
    pickUpDate: format(bookingCart.pickUpDate as Date, 'yyyy-MM-dd HH:mm'),
    dropOffDate: format(bookingCart.dropOffDate as Date, 'yyyy-MM-dd HH:mm'),
    driverAge: bookingCart.driverAge,
    fleetCategory: bookingCart.fleetCategory?.categoryCode as string,
    campaignCode: bookingCart.campaignCode,
    insurance: bookingCart.selectedExcess?.productCode as string,
    secondaryProducts: bookingCart.selectedExtras
        ? Object.values(toJS(bookingCart.selectedExtras)).reduce(
              (map, product) => {
                  const available = bookingCart.secondaryProductsFlat.find((p) => p.productCode === product.productCode);
                  if (product.productCode && product.qty > 0 && available) {
                      map[product.productCode] = product.qty;
                  }
                  return map;
              },
              {} as Record<string, number>
          )
        : {},
    hirerDetails: {
        firstName: bookingCart.hirerDetails.firstName,
        lastName: bookingCart.hirerDetails.lastName,
        mobileNumber: bookingCart.hirerDetails.mobileNumber,
        email: bookingCart.hirerDetails.email,
        driversLicenceCountry: bookingCart.hirerDetails.driversLicenceCountry,
        acceptedTerms: bookingCart.hirerDetails.acceptedTerms || false,
        mailingList: bookingCart.hirerDetails.mailingList || false,
        loyalty: bookingCart.hirerDetails.loyaltyId?.id ? { type: bookingCart.hirerDetails.loyaltyId.type || '', id: bookingCart.hirerDetails.loyaltyId.id || '' } : undefined,
    },
    voucherReference: bookingCart.hirerDetails.voucherReference,
    agentName: bookingCart.hirerDetails.agentName,
    gateway: bookingCart.paymentType?.gateway,
    payment: bookingCart.paymentType?.id,
    numberOfPeople: bookingCart.hirerDetails.numberOfPeople,
});

export const isBookingCartValid = (bookingCart: unknown, ignoreInsurance?: boolean): bookingCart is Required<BookingCart> => {
    const typed = bookingCart as BookingCart;
    if (!ignoreInsurance && !typed.selectedExcess) {
        return false;
    }
    return Boolean(typed.pickUpLocation && typed.dropOffLocation && typed.pickUpDate && typed.hirerDetails);
};

export class BookingCart implements Omit<Partial<TripDetails>, 'pickUpLocation' | 'dropOffLocation'> {
    amendments?: Partial<BookingCart> = undefined;
    pickUpLocation?: Site = undefined;
    dropOffLocation?: Site = undefined;
    selectedExcess?: ObeProductLine = undefined;
    mandatoryItems?: ObeProductLine[] = undefined;
    selectedExtras?: ObeProductLine[] = undefined;
    pickUpDate?: Date = undefined;
    dropOffDate?: Date = undefined;
    rentalDays?: number = undefined;
    driverAge?: number = undefined;
    fleetCategory?: FleetCategory = undefined;
    secondaryProducts?: SecondaryProductGroup[] = undefined;
    insuranceProducts?: InsuranceProductGroup = undefined;
    reservationReference?: string = undefined;
    reservationId?: number = undefined;
    isRelocationDeal?: boolean = false;
    hirerDetails: HirerDetailsSummary = {
        firstName: '',
        lastName: '',
        mobileNumber: '',
        email: '',
        driversLicenceCountry: '',
        acceptedTerms: false,
        mailingList: false,
        numberOfPeople: 1,
        voucherReference: '',
        loyaltyId: {},
    };
    paymentTypeId?: string = undefined;
    campaignCode?: string = undefined;
    convertUrl?: string = undefined;
    paymentToken?: string = undefined;
    userMode?: UserMode = undefined;
    action?: 'create' | 'edit' = 'create';
    agentPaymentType?: number = undefined;
    agentCommission?: CurrencyValue = undefined;
    bundles: ProductBundle[] = [];
    payments: BookingPaymentInfo[] = [];
    bookingStatus: BookingStatus = 'quote';

    constructor(props?: Partial<BookingCart>) {
        Object.assign(this, props);
        makeAutoObservable(this);
    }

    get lines(): ObeProductLine[] {
        const bundledProductIds = this.bundledItems.map((bundle) => bundle.productId);
        return [...(this.mandatoryItems || []), ...(this.selectedExcess ? [this.selectedExcess] : []), ...(this.selectedExtras || [])].filter(
            (line) => !bundledProductIds.includes(line.productId)
        );
    }

    get selectedFixedExtras(): ObeProductLine[] {
        return this.selectedExtras?.filter((line) => line.productType != 'Percentage') || [];
    }

    get selectedPercentageExtras(): ObeProductLine[] {
        return this.selectedExtras?.filter((line) => line.productType === 'Percentage') || [];
    }

    get totalPrice(): CurrencyValue {
        if (this.fleetCategory?.total.currencyCode) {
            const rateTotal = this.fleetCategory?.total.value || 0;
            const mandatoryTotal = this.mandatoryItems?.filter((l) => !l.isExcluded).reduce((acc, cur) => acc + cur.total.value, 0) || 0;
            const excessTotal = this.selectedExcess?.total.value || 0;
            const extrasTotal = this.selectedFixedExtras?.filter((l) => !l.isExcluded).reduce((acc, cur) => acc + getTotalLineValue(cur, 0, rateTotal), 0) || 0;
            const subTotal = rateTotal + mandatoryTotal + excessTotal + extrasTotal;
            const percentageExtrasTotal = this.selectedPercentageExtras?.filter((l) => !l.isExcluded).reduce((acc, cur) => acc + getTotalLineValue(cur, subTotal, rateTotal), 0) || 0;
            const merchantFeeTotals = (this.payments || [])
                .filter((payment) => payment.merchantFeeAmount?.value)
                .reduce((acc, cur) => acc + (cur.merchantFeeAmount?.value ? cur.merchantFeeAmount.value : 0), 0);

            const paymentsTotal = (this.payments || []).filter((payment) => payment.total?.value).reduce((acc, cur) => acc + (cur.total?.value ? cur.total.value : 0), 0);

            return {
                ...this.fleetCategory.total,
                value: subTotal + percentageExtrasTotal + merchantFeeTotals - paymentsTotal,
            };
        }
        return {
            currencyCode: '',
            value: 0,
        };
    }

    get paymentType(): PaymentOption | undefined {
        return this.paymentTypeId ? paymentStore.getActivePaymentOption(this) : undefined;
    }

    get totalPriceWithMerchantFee(): CurrencyValue {
        const merchantFee = Number(this?.paymentType?.merchantFee?.value || 0);
        const totalPrice = Number(this?.totalPrice?.value || 0);
        return {
            ...this.totalPrice,
            value: merchantFee + totalPrice,
        };
    }

    get fleetType(): FleetType | null {
        return FleetTypesStore?.getFleetTypeBySlug(this.fleetCategory?.fleetTypeCode) || null;
    }

    asCreateRequest(): Omit<CreateBookingRequest, 'type'> | null {
        if (isBookingCartValid(this)) {
            return bookingCartToCreateBookingRequest(this);
        }
        return null;
    }

    asEmailQuoteRequest(): EmailQuoteRequest | null {
        if (isBookingCartValid(this, true)) {
            return {
                ...(bookingCartToCreateBookingRequest(this) as EmailQuoteRequest),
                reservationReference: this.reservationReference,
            };
        }
        return null;
    }

    asSearchFormValues(): PartialSearchFormValues {
        return {
            fleetType: this.fleetType || undefined,
            pickUpLocation: this.pickUpLocation || undefined,
            pickUpDate: this.pickUpDate,
            pickUpTime: this.pickUpDate,
            dropOffLocation: this.dropOffLocation || undefined,
            dropOffDate: this.dropOffDate,
            dropOffTime: this.dropOffDate,
            fleetCategoryCode: this.fleetCategory?.categoryCode || undefined,
        };
    }

    asFunnelTrip(): AnalyticsJucyBookingFunnelTrip | undefined {
        const trip = {
            fleetType: this.fleetType?.slug,
            pickUpLocation: this.pickUpLocation?.SiteCode,
            pickUpDate: this.pickUpDate,
            dropOffLocation: this.dropOffLocation?.SiteCode,
            dropOffDate: this.dropOffDate,
            coupon: this.campaignCode,
            brand: mapFleetTypeSlugToBrand(this.fleetType?.slug),
            region: this.pickUpLocation?.CountryCode,
        };
        if (!trip.fleetType || !trip.pickUpLocation || !trip.pickUpDate || !trip.dropOffLocation || !trip.dropOffDate || !trip.brand || !trip.region) {
            return undefined;
        }
        return trip as AnalyticsJucyBookingFunnelTrip;
    }

    getSearchFormInitialValues({
        searchParams,
        defaults,
    }: {
        searchParams?: URLSearchParamsInit | Partial<Record<string, string>>;
        defaults?: Partial<SearchFormValues>;
    } = {}): Partial<SearchFormValues> {
        const mergedValues = {
            ...stripEmptyProps(defaults),
            ...stripEmptyProps(this.asSearchFormValues()),
            ...stripEmptyProps(queryStringToSearchFormValues(searchParams)),
        };
        return {
            ...mergedValues,
            dropOffLocation: mergedValues?.pickUpLocation?.Country === mergedValues?.dropOffLocation?.Country ? mergedValues.dropOffLocation : undefined,
            togglePromoCode: Boolean(this.fleetCategory?.campaignCode || mergedValues.promoCode),
        };
    }

    get productLines(): ObeProductLine[] {
        return [...(this.mandatoryItems || []), ...(this.selectedExcess ? [this.selectedExcess] : []), ...(this.selectedExtras || [])];
    }

    get isOnRequest(): boolean {
        return this.fleetCategory?.availability === 'OnRequest';
    }

    get isQuote(): boolean {
        return this.bookingStatus === 'quote';
    }

    get bundledItems(): ObeProductLine[] {
        const result: ObeProductLine[] = [];
        for (const line of this.productLines) {
            const bundle = this.bundles.find((bundle) => bundle.productCode === line.productCode);
            for (const item of bundle?.bundledProducts || []) {
                result.push({
                    ...item,
                    total: {
                        ...item.price,
                    },
                    qty: 1,
                });
            }
        }
        return result;
    }

    get proposedProductsMap(): CartItem[] {
        const proposedProductsMap = [];
        if (this.selectedExcess) {
            proposedProductsMap.push({
                code: this.selectedExcess.productCode,
                qty: 1,
            });
        }
        if (this.selectedExtras) {
            for (const selectedExtra of this.selectedExtras) {
                proposedProductsMap.push({
                    code: selectedExtra.productCode,
                    qty: selectedExtra.qty,
                });
            }
        }
        return proposedProductsMap;
    }

    updateSelectedExtras(item: ProductLine) {
        const items = this.selectedExtras?.filter((product) => product.productId !== item.productId)?.sort((p) => p.sortOrder || 0) || [];
        items.push(item);

        if (isObservableArray(this.selectedExtras)) {
            this.selectedExtras.replace(items);
        } else {
            this.selectedExtras = items;
        }
    }

    get canEmailQuote(): boolean {
        if (!['draft', 'quote', 'payment-required'].includes(this.bookingStatus)) {
            return false;
        }
        if (this.userMode !== 'direct') {
            return false;
        }
        return this.fleetCategory?.availability === 'FreeSell';
    }

    get hasTripInfo() {
        return Boolean(this.pickUpLocation && this.pickUpDate && this.dropOffLocation && this.dropOffDate);
    }

    get secondaryProductsFlat(): SecondaryProduct[] {
        return this.secondaryProducts?.reduce((acc, cur) => [...acc, ...cur.items], [] as SecondaryProduct[]) || [];
    }

    get insuranceProductsFlat(): InsuranceProduct[] {
        return this.insuranceProducts?.items || [];
    }

    addSecondaryProduct(productCode: string, quantity = 1) {
        const secondaryItem = this.secondaryProductsFlat.find((i) => i.productCode === productCode);
        if (secondaryItem) {
            const item = Product.fromV3Product({
                ...secondaryItem,
                qty: Number(quantity),
            } as ObeProductLine).asV3ProductLine();
            this.updateSelectedExtras(item);
        }
    }

    setSelectedInsurance(productCode: string) {
        const selectedExcess = this?.insuranceProductsFlat.find((i) => i.productCode === productCode);
        if (selectedExcess) {
            this.selectedExcess = InsuranceProduct1.fromV3InsuranceProduct(selectedExcess).asV3ProductLine();
        }
    }

    get isEditReservation(): boolean {
        return Boolean(this?.reservationReference && !this.isQuote && this?.action === 'edit');
    }

    get reference() {
        return `#${this.pickUpLocation?.SiteCode}-${this.reservationId}`;
    }
    getPaymentRedirectUrl(query?: Record<string, string | unknown>) {
        const callbackParams = {
            ...query,
            scenario: 'convert',
            ref: this.reservationReference,
            gateway: this.paymentType?.gateway || '',
        };

        const result = new URL(this.convertUrl || '', config.apiBaseUrl);
        result.searchParams.set('returnUrl', getPaymentCallbackUrl({ ...callbackParams, success: 'true' }));
        result.searchParams.set('failedUrl', getPaymentCallbackUrl({ ...callbackParams, success: 'false' }));
        result.searchParams.set('type', this.paymentTypeId === 'deposit' ? 'deposit' : 'full');
        result.searchParams.set('gateway', this.paymentType?.gateway || '');
        if (this.paymentType?.gateway === 'stripe') {
            result.searchParams.delete('callback');
        }
        return result.href;
    }
    get selectedExcessLine() {
        const excessProduct = this.selectedExcess ? this.insuranceProductsFlat.find((item) => item.productCode === this.selectedExcess?.productCode) : undefined;
        return this.selectedExcess && excessProduct
            ? {
                  ...this.selectedExcess,
                  excessType: excessProduct.excessType,
                  excess: excessProduct.excess,
                  bond: excessProduct.bond,
                  category: InsuranceProductLineCategoryEnum.InsuranceFee,
              }
            : undefined;
    }

    asBooking() {
        return BookingFromJSON({
            ...this,
            pickUpLocation: this.pickUpLocation?.SiteCode || '',
            pickUpDate: this.pickUpDate?.toJSON(),
            dropOffDate: this.dropOffDate?.toJSON(),
            dropOffLocation: this.dropOffLocation?.SiteCode || '',
            reservationReference: this.reservationReference || '',
            reservationId: this.reservationId || 0,
            quoteId: '',
            status: this.bookingStatus as BookingStatus,
            dateCreated: new Date().toJSON(),
            totalPrice: this.totalPrice,
            rentalDays: this.rentalDays || 0,
            driverAge: this.driverAge || 0,
            fleetCategory: this.fleetCategory as FleetCategory,
        });
    }
}
